import { useState, useEffect } from 'react';

const FindRemaining = (deadline) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const leading0 = (num) => {
    return num < 10 ? '0' + num : num;
  };

  const getTimeUntil = (deadline) => {
    const time = Date.parse(deadline) - Date.parse(new Date());

    if (time < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    } else {
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    }
  };

  useEffect(() => {
    setInterval(() => getTimeUntil(deadline), 1000);

    return () => getTimeUntil(deadline);
  }, [deadline]);

  //   return '' + days + ':' + leading0(hours) + ':' + leading0(minutes) + ':' + leading0(seconds) + '';
  return days > 4
    ? '' + days + ' days'
    : days >= 2
    ? '' + days + ' days, ' + hours + ' hours'
    : days >= 1
    ? '' + leading0(hours + 24) + 'h:' + leading0(minutes) + 'm'
    : hours >= 1
    ? '' + leading0(hours) + 'h:' + leading0(minutes) + 'm'
    : seconds > 0
    ? '' + leading0(minutes) + 'm:' + leading0(seconds) + 's'
    : -1;
};

export default FindRemaining;

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Fade, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';

import React from 'react';

import PropulseCard from '../../components/core/Universal/PropulseCard';
import AboutHistoryYear from './AboutHistoryYear';

function AboutHistory() {
  const [year, setYear] = React.useState(2023);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(() => {
    if (mobile) return false;
    return true;
  });

  const dynamicLength = () => {
    switch (year) {
      case 2018:
        return 10;
      case 2019:
        return 1390.58 / 6;
      case 2020:
        return (1390.58 / 6) * 2;
      case 2021:
        return (1390.58 / 6) * 3;
      case 2022:
        return (1390.58 / 6) * 4;
      case 2023:
        return (1390.58 / 6) * 5;
      default:
        return 1390.58;
    }
  };

  const dynamicRocketPosition = () => {
    switch (year) {
      case 2018:
        return -1400;
      case 2019:
        return -(1400 / 6) * 5;
      case 2020:
        return -(1400 / 6) * 4;
      case 2021:
        return -(1400 / 6) * 3;
      case 2022:
        return -(1400 / 6) * 2;
      case 2023:
        return -(1400 / 6);
      default:
        return -80;
    }
  };

  const dynamicText = () => {
    switch (year) {
      case 2018:
        return 'Propulse NTNU was founded by a group of rocket enthusiasts, and the team immediately started developing our first rocket, Sleipner.';

      case 2019:
        return 'The development of Sleipner continued, and our very first rocket was launched just a year later at Spaceport America Cup (SA Cup) - the first Nordic rocket to do so. ';

      case 2020:
        return 'For our second rocket, named Mjølner, the team attempted to integrate the ongoing hybrid-project into the competing rocket. But, due to development delays the team was required to change engines midway, before ultimately never finishing the rocket due to the pandemic.';

      case 2021:
        return 'Team 2021 developed our first super-sonic rocket, Stetind. At the digital SA Cup, Stetind placed 1st in its class and 2nd overall based on a technical report. Four months later, Stetind was launched to 9700m at EuRoC, placing 1st in its class there too.';

      case 2022:
        return 'The same semester as Stetind was launched, the team started developing its successor, Birkeland. We went back to the US for the first time since 2019 to compete at SA Cup, where it was launched to 9100m and placed 2nd in its class, and 3rd overall in the competition.';

      case 2023:
        return 'After the successes of Stetind and Birkeland, the team started working on Bifrost; our first rocket with a self-developed bi-liquid engine. Utilizing the knowledge and test-facilities from our hybrid engine R&D project, and building upon largely flight-proven designs, Bifrost was fully researched and developed in-house. The team competed in EuRoC in October 2023, where we came Bifrost had the best flight performance in the 3000 m Liquid category. Bifrost was also the first Norwegian-made bi-liquid rocket to ever launch.';

      case 2024:
        return 'For 2024’s project, we are further improving upon Bifrost’s design in order to reach the goal of 10 000 m. And maybe this time, it’ll be from Norwegian soil.';

      default:
        return 'Next year’s project will continue our work towards our long-term goal of reaching space with a bi-liquid rocket.';
    }
  };

  return (
    <Grid
      container
      sx={{
        position: 'relative',
        py: { xs: 0, md: 14 }
      }}>
      <Grid
        item
        xs={5}
        md={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
        gap="16%">
        <AboutHistoryYear cardyear={2018} year={year} setYear={setYear} setOpen={setOpen} />
        <AboutHistoryYear cardyear={2020} year={year} setYear={setYear} setOpen={setOpen} />
        <AboutHistoryYear cardyear={2022} year={year} setYear={setYear} setOpen={setOpen} />
        <AboutHistoryYear cardyear={2024} year={year} setYear={setYear} setOpen={setOpen} />
      </Grid>

      <Grid item xs={2} md={12}>
        {mobile ? (
          <motion.svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 145.6 1920">
            <defs>
              <linearGradient
                id="linear-gradient"
                x1="-622.49"
                y1="840.57"
                x2="768.09"
                y2="840.57"
                gradientTransform="translate(0 238.86)"
                gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#00a6ff" />
                <stop offset="1" stopColor={theme.palette.primary.main} />
              </linearGradient>
            </defs>
            <motion.g style={{ translateY: -100 }}>
              <g id="bar_bg">
                <path
                  d="m72.8,1778.22c-8.32,0-15.09-6.77-15.09-15.09V395.74c0-8.32,6.77-15.09,15.09-15.09s15.09,6.77,15.09,15.09v1367.39c0,8.32-6.77,15.09-15.09,15.09Z"
                  fill={theme.palette.action.selected}
                />
                <path
                  d="m72.8,384.14c6.4,0,11.59,5.19,11.59,11.59v1367.39c0,6.4-5.19,11.59-11.59,11.59s-11.59-5.19-11.59-11.59V395.74c0-6.4,5.19-11.59,11.59-11.59m0-7c-10.25,0-18.59,8.34-18.59,18.59v1367.39c0,10.25,8.34,18.59,18.59,18.59s18.59-8.34,18.59-18.59V395.74c0-10.25-8.34-18.59-18.59-18.59h0Z"
                  fill={theme.palette.grey[400]}
                />
              </g>
              <motion.rect
                id="bar"
                x="61.21"
                y="384.14"
                width="23.18"
                height="1390.58"
                rx="11.59"
                ry="11.59"
                fill="url(#linear-gradient)"
                style={{ rotate: '180deg' }}
                animate={{ height: dynamicLength() }}
              />
              ƒ
              <motion.g
                id="rocket"
                style={{ scale: 0.7 }}
                animate={{ translateY: -1 * dynamicRocketPosition() }}>
                <path
                  d="m129.59,576.01c-.38,0-.77-.07-1.13-.22l-33.35-13.61-9.09,11.69c-.57.73-1.44,1.16-2.37,1.16h-21.93c-.92,0-1.8-.43-2.37-1.15l-9.04-11.59-33.16,13.5c-.36.15-.75.22-1.13.22-.59,0-1.17-.17-1.67-.51-.83-.56-1.33-1.49-1.33-2.49v-20.22c0-.8.32-1.56.88-2.12l35.28-35.29V231.73c-.01-.1,0-.21,0-.31,3.92-37.33,8.13-62.99,12.51-76.27,3.53-10.68,6.95-12.87,11-12.87s7.48,2.19,11.01,12.89c4.39,13.32,8.59,39.04,12.48,76.45.01.1.02.21.02.31v283.2l35.52,35.53c.56.56.88,1.33.88,2.12v20.23c0,1-.51,1.93-1.34,2.49-.5.34-1.09.51-1.67.51Z"
                  fill="#007eed"
                />
                <path
                  d="m72.69,145.28c3.43,0,11.5,0,20.51,86.65v284.44l36.4,36.41v20.23s-35.47-14.47-35.47-14.47l-10.49,13.48h-21.93l-10.44-13.38-35.26,14.36v-20.22l36.16-36.17V231.73c9.06-86.45,17.09-86.45,20.52-86.45m0-6c-6.33,0-10.35,4.32-13.85,14.92-4.46,13.49-8.71,39.36-12.65,76.9-.02.21-.03.42-.03.63v282.4s-34.39,34.41-34.39,34.41c-1.12,1.13-1.76,2.65-1.76,4.24v20.22c0,2,.99,3.86,2.65,4.98,1,.68,2.17,1.02,3.35,1.02.77,0,1.53-.15,2.26-.44l31.06-12.65,7.65,9.8c1.14,1.46,2.88,2.31,4.73,2.31h21.93c1.85,0,3.6-.85,4.74-2.32l7.7-9.89,31.25,12.75c.73.3,1.5.44,2.27.44,1.18,0,2.34-.34,3.35-1.02,1.66-1.11,2.65-2.98,2.65-4.98v-20.23c.01-1.59-.62-3.12-1.75-4.24l-34.64-34.65V231.93c0-.21-.01-.41-.03-.62-3.91-37.62-8.16-63.56-12.62-77.08-3.5-10.62-7.52-14.95-13.86-14.95h0Z"
                  fill="#e6e6e6"
                />
              </motion.g>
            </motion.g>
          </motion.svg>
        ) : (
          <motion.svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920 145.6">
            <defs>
              <linearGradient
                id="linear-gradient"
                x1="264.71"
                y1="72.8"
                x2="1655.29"
                y2="72.8"
                gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#00a6ff" />
                <stop offset="1" stopColor={theme.palette.primary.main} />
              </linearGradient>
            </defs>
            <g id="bar_bg">
              <path
                d="m276.3,87.9c-8.32,0-15.09-6.77-15.09-15.09s6.77-15.09,15.09-15.09h1367.39c8.32,0,15.09,6.77,15.09,15.09s-6.77,15.09-15.09,15.09H276.3Z"
                fill={theme.palette.action.selected}
              />
              <path
                d="m1643.7,61.21c6.4,0,11.59,5.19,11.59,11.59s-5.19,11.59-11.59,11.59H276.3c-6.4,0-11.59-5.19-11.59-11.59s5.19-11.59,11.59-11.59h1367.39m0-7H276.3c-10.25,0-18.59,8.34-18.59,18.59s8.34,18.59,18.59,18.59h1367.39c10.25,0,18.59-8.34,18.59-18.59s-8.34-18.59-18.59-18.59h0Z"
                fill={theme.palette.grey[400]}
              />
            </g>
            <motion.rect
              id="bar"
              x="264.71"
              y="61.21"
              width="1390.58"
              height="23.19"
              rx="11.59"
              ry="11.59"
              fill="url(#linear-gradient)"
              animate={{ width: dynamicLength() }}
            />
            <motion.g
              id="rocket"
              style={{ scale: 0.7 }}
              animate={{ translateX: dynamicRocketPosition() }}>
              <path
                d="m1466.43,132.59c-1,0-1.93-.5-2.49-1.33s-.67-1.88-.29-2.81l13.61-33.35-11.69-9.09c-.73-.57-1.16-1.44-1.16-2.37v-21.93c0-.92.43-1.8,1.15-2.37l11.59-9.04-13.5-33.16c-.38-.92-.27-1.98.29-2.81.56-.83,1.49-1.33,2.49-1.33h20.22c.8,0,1.56.32,2.12.88l35.29,35.28h283.64c.1,0,.21,0,.31,0,37.33,3.92,62.99,8.13,76.27,12.51,10.68,3.53,12.87,6.95,12.87,11s-2.19,7.48-12.89,11.01c-13.32,4.39-39.04,8.59-76.45,12.48-.1.01-.21.02-.31.02h-283.2l-35.53,35.52c-.56.56-1.33.88-2.12.88h-20.23Z"
                fill="#007eed"
              />
              <path
                d="m1486.66,16.01l36.17,36.16h284.88c86.45,9.06,86.45,17.09,86.45,20.52s0,11.5-86.65,20.51h-284.44l-36.41,36.4h-20.23s14.47-35.47,14.47-35.47l-13.48-10.49v-21.93l13.38-10.44-14.36-35.26h20.22m0-6h-20.22c-2,0-3.86.99-4.98,2.65-1.11,1.66-1.33,3.76-.58,5.61l12.65,31.06-9.8,7.65c-1.46,1.14-2.31,2.88-2.31,4.73v21.93c0,1.85.85,3.6,2.32,4.74l9.89,7.7-12.75,31.25c-.75,1.85-.54,3.95.57,5.61,1.11,1.66,2.98,2.65,4.98,2.65h20.23s0,.01,0,.01c1.59,0,3.12-.63,4.24-1.76l34.65-34.64h281.96c.21,0,.41-.01.62-.03,37.62-3.91,63.56-8.16,77.08-12.62,10.62-3.5,14.95-7.52,14.95-13.86s-4.32-10.35-14.92-13.85c-13.49-4.46-39.36-8.71-76.9-12.65-.21-.02-.42-.03-.63-.03h-282.4s-34.41-34.39-34.41-34.39c-1.13-1.12-2.65-1.76-4.24-1.76h0Z"
                fill="#e6e6e6"
              />
            </motion.g>
          </motion.svg>
        )}
      </Grid>

      <Grid
        item
        xs={5}
        md={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
        gap="16%">
        <AboutHistoryYear cardyear={2019} year={year} setYear={setYear} setOpen={setOpen} reverse />
        <AboutHistoryYear cardyear={2021} year={year} setYear={setYear} setOpen={setOpen} reverse />
        <AboutHistoryYear cardyear={2023} year={year} setYear={setYear} setOpen={setOpen} reverse />
      </Grid>

      <Fade in={open}>
        <Box>
          <PropulseCard
            sx={
              year <= 2020
                ? {
                    position: { xs: 'fixed', md: 'absolute' },
                    bottom: 20,
                    height: { xs: 'none', md: '100%' },
                    maxWidth: { xs: '60%', md: '25%' },
                    boxShadow: 8,
                    bgcolor: `${theme.palette.background.paper}cc`,
                    backdropFilter: 'blur(6px)',
                    zIndex: 500,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    // If hover over a left year card, the text will be on the right
                    right: 20
                  }
                : {
                    position: { xs: 'fixed', md: 'absolute' },
                    bottom: 20,
                    height: { xs: 'none', md: '100%' },
                    maxWidth: { xs: '60%', md: '25%' },
                    boxShadow: 8,
                    bgcolor: `${theme.palette.background.paper}cc`,
                    backdropFilter: 'blur(6px)',
                    zIndex: 500,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    // If hover over a right year card, the text will be on the left
                    left: 20
                  }
            }>
            <IconButton onClick={() => setOpen(false)} sx={{ m: 1 }}>
              <CloseRoundedIcon fontSize="large" />
            </IconButton>
            <Typography variant="body1" px={4} pb={4}>
              {dynamicText()}
            </Typography>
          </PropulseCard>
        </Box>
      </Fade>
    </Grid>
  );
}

export default AboutHistory;

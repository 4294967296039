import { Box, Card, CardActionArea, Fade } from '@mui/material';
import React from 'react';
import TimelinePopup from '../TimelinePopup/TimelinePopup';
import './TimelineTile.css';

const TimelineTile = ({ ImageURL, open, handleOpen, handleClose, caption }) => {
  return (
    <>
      <Fade in timeout={1000}>
        <Card
          elevation={8}
          sx={{
            // width: '340px',
            // height: { xs: 250, md: 250 },
            width: '100%',
            maxWidth: '340px',
            height: { xs: '150px', sm: '250px', md: '200px', lg: '250px' },
            borderRadius: 8,
            position: 'relative',
            transform: 'scale(1)',
            '&:hover': {
              transform: 'scale(1.02)'
            },
            backgroundColor: 'transparent'
          }}>
          <Box
            component={CardActionArea}
            disableRipple
            disableTouchRipple
            sx={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', backgroundColor: 'transparent' }}
            width="100%"
            height="100%"
            onClick={() => handleOpen(ImageURL)}>
            <Box
              sx={{
                height: '100%',
                width: '100%',
                background: `url(${ImageURL})`,
                backgroundSize: 'contain',
                backgroundcolor: 'transparent !important',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}
            />
          </Box>
        </Card>
      </Fade>
      <TimelinePopup
        imageURL={ImageURL}
        openPopup={open == ImageURL}
        closePopup={handleClose}
        caption={caption}
      />
    </>
  );
};

export default TimelineTile;

import {
  Box,
  Container,
  Divider,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import React from 'react';
import BirkelandWholesome from '../../assets/images/rocket-projects/2022-birkeland/IMG_5038.jpg';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import PropulseCard from '../../components/core/Universal/PropulseCard';
import * as Media from './MediaList.json';

const MediaOverview = () => {
  const MediaStr = JSON.stringify(Media);
  const MediaObj = JSON.parse(MediaStr);
  const articles = MediaObj.articles.publishers.sort(mediaSorter);
  const videos = MediaObj.videos.publishers.sort(mediaSorter);
  const podcasts = MediaObj.podcasts.publishers.sort(mediaSorter);

  function mediaSorter(a, b) {
    return a.name.localeCompare(b.name);
  }

  return (
    <Fade in>
      <Box>
        <Header />
        <Box
          mb={4}
          sx={{
            height: '500px',
            width: '100%',
            background: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${BirkelandWholesome})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Typography
            variant="h1"
            color="white"
            fontSize={{ xs: 50, sm: 70, md: 90 }}
            fontWeight={500}
            textAlign="center"
            sx={{
              textShadow: 'rgb(0 0 0 / 40%) 0px 4px 5px'
            }}>
            Media Overview
          </Typography>
        </Box>
        <Container maxWidth="md">
          <PropulseCard sx={{ p: 4, mb: 4 }}>
            <Grid container spacing={4} pb={8}>
              <Grid item xs={12}>
                <Typography
                  fontSize={{ xs: '2.7rem', sm: '3rem', md: '3.75rem' }}
                  color="primary"
                  fontWeight={500}
                  textAlign="center"
                  pb={4}>
                  Articles
                </Typography>
                <Divider />
              </Grid>
              {articles.map((publisher, publisherIndex) => (
                <Grid item xs={12} md={6} key={publisherIndex}>
                  <Typography
                    fontSize={{ xs: '1.5rem', sm: '1.7rem', md: '2.2rem' }}
                    fontWeight="400"
                    ml={{ xs: 2, sm: 8, md: 0 }}>
                    {publisher.name}
                  </Typography>
                  <List sx={{ ml: { xs: 2, sm: 8, md: 0 } }}>
                    {publisher.articles.map((article, articleIndex) => (
                      <ListItem key={articleIndex}>
                        <ListItemButton component="a" href={article.url} target="_blank">
                          <ListItemText
                            primary={article.title}
                            sx={{ textDecoration: 'underline' }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </PropulseCard>
          <PropulseCard sx={{ p: 4, mb: 4 }}>
            <Grid container spacing={4} pb={8}>
              <Grid item xs={12}>
                <Typography
                  fontSize={{ xs: '2.7rem', sm: '3rem', md: '3.75rem' }}
                  color="primary"
                  fontWeight={500}
                  textAlign="center"
                  pb={4}>
                  Videos
                </Typography>
                <Divider />
              </Grid>
              {videos.map((publisher, publisherIndex) => (
                <Grid item xs={12} md={6} key={publisherIndex}>
                  <Typography
                    fontSize={{ xs: '1.5rem', sm: '1.7rem', md: '2.2rem' }}
                    fontWeight="400"
                    ml={{ xs: 4, sm: 8, md: 0 }}>
                    {publisher.name}
                  </Typography>
                  <List sx={{ ml: { xs: 4, sm: 8, md: 0 } }}>
                    {publisher.videos.map((video, videoIndex) => (
                      <ListItem key={videoIndex}>
                        <ListItemButton component="a" href={video.url} target="_blank">
                          <ListItemText
                            primary={video.title}
                            sx={{ textDecoration: 'underline' }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </PropulseCard>
          <PropulseCard sx={{ p: 4, mb: 4 }}>
            <Grid container spacing={4} pb={8}>
              <Grid item xs={12}>
                <Typography
                  fontSize={{ xs: '2.7rem', sm: '3rem', md: '3.75rem' }}
                  color="primary"
                  fontWeight={500}
                  textAlign="center"
                  pb={4}>
                  Podcasts
                </Typography>
                <Divider />
              </Grid>
              {podcasts.map((publisher, publisherIndex) => (
                <Grid item xs={12} md={6} key={publisherIndex}>
                  <Typography
                    fontSize={{ xs: '1.5rem', sm: '1.7rem', md: '2.2rem' }}
                    fontWeight="400"
                    ml={{ xs: 4, sm: 8, md: 0 }}>
                    {publisher.name}
                  </Typography>
                  <List sx={{ ml: { xs: 4, sm: 8, md: 0 } }}>
                    {publisher.podcasts.map((podcast, podcastIndex) => (
                      <ListItem key={podcastIndex}>
                        <ListItemButton component="a" href={podcast.url} target="_blank">
                          <ListItemText
                            primary={podcast.title}
                            sx={{ textDecoration: 'underline' }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </PropulseCard>
        </Container>
        <Footer />
      </Box>
    </Fade>
  );
};

export default MediaOverview;

import {
  Box,
  ClickAwayListener,
  Container,
  Fade,
  Grow,
  IconButton,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  useTheme
} from '@mui/material';

import React from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../components/Header/Header';

import './MemberList.css';
import MemberList from './MemberList';

import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

import sleipnerTeam from '../../assets/images/rocket-projects/2019-sleipner/team2019.jpg';
import mjolnerTeam from '../../assets/images/rocket-projects/2020-mjoelner/team2020.jpg';
import stetindTeam from '../../assets/images/rocket-projects/2021-stetind/team2021.jpg';
import birkelandTeam from '../../assets/images/rocket-projects/2022-birkeland/Birkeland_SAC_team_shrunk.jpg';
import bifrostTeam from '../../assets/images/rocket-projects/2023-bifrost/bifrost_euroc_group_photo2.webp';
import valemonTeam from '../../assets/images/rocket-projects/2024-valemon/team24.webp';

const Team = () => {
  const theme = useTheme();
  const [loaded, setLoaded] = React.useState(false);
  const [team, setTeam] = React.useState(2024);
  const [openTeamSelect, setOpenTeamSelect] = React.useState(false);
  const { chosenGroup } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const trigger = useScrollTrigger({
    target: undefined,
    disableHysteresis: true,
    threshold: isMobile ? 100 : 300
  });

  const teams = [2024, 2023, 2022, 2021, 2020, 2019];

  const teamPictures = {
    2019: sleipnerTeam,
    2020: mjolnerTeam,
    2021: stetindTeam,
    2022: birkelandTeam,
    2023: bifrostTeam,
    2024: valemonTeam
  };

  const handleClickAway = () => {
    setOpenTeamSelect(false);
  };

  return (
    <>
      <Header
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 1000
        }}
      />
      <Fade in={loaded}>
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 100,
              width: '100%',
              height: 120,
              background: (theme) =>
                `linear-gradient(0deg, ${theme.palette.background.default}00 0%, ${theme.palette.background.default}  50%)`
            }}
          />

          <Fade in={!trigger}>
            <Box
              position="absolute"
              display="flex"
              justifyContent="center"
              sx={{ userSelect: 'none', width: '100%' }}>
              <img
                alt={`Team ${team}`}
                src={teamPictures[team]}
                style={{
                  paddingTop: isMobile ? '60px' : '0',
                  width: '100%',
                  height: isMobile ? '45vh' : '100vh',
                  objectFit: 'cover'
                }}
                onLoad={() => setLoaded(true)}
              />
            </Box>
          </Fade>

          <Box
            sx={{
              width: '100%',
              height: { xs: '43vh', md: '95vh' },
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end'
            }}>
            <Box
              display="flex"
              alignItems="center"
              p={{ xs: 5, sm: 5, md: 6 }}
              gap={{ xs: 0, sm: 2, md: 4 }}
              sx={{
                userSelect: 'none'
              }}>
              <IconButton
                disabled={team <= 2019}
                onClick={() => setTeam((t) => t - 1)}
                sx={{
                  height: 80,
                  borderRadius: 4,
                  color: trigger ? 'text.primary' : 'white'
                }}>
                <KeyboardArrowLeftRoundedIcon sx={{ fontSize: '3rem' }} />
              </IconButton>

              {/* Team Selection Box */}
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box
                  onMouseEnter={() => setOpenTeamSelect(true)}
                  onMouseLeave={() => setOpenTeamSelect(false)}
                  sx={{
                    bgcolor: 'none',

                    borderRadius: 6,
                    p: 2,
                    display: 'flex',
                    gap: { xs: 0, sm: 1, md: 2 },
                    cursor: 'pointer',
                    position: 'relative',
                    width: { xs: 300, sm: 420, md: 800 },
                    '&:hover': {
                      bgcolor: `${theme.palette.primary.main}44`,
                      backdropFilter: 'blur(12px)'
                    }
                  }}>
                  <Typography
                    variant="h1"
                    fontWeight={700}
                    fontSize={{ xs: 50, sm: 70, md: 140 }}
                    sx={{
                      color: trigger ? 'text.primary' : 'white',
                      mixBlendMode: trigger ? 'normal' : 'screen',
                      opacity: 0.8
                    }}>
                    TEAM
                  </Typography>
                  <Typography
                    variant="h1"
                    fontWeight={700}
                    fontSize={{ xs: 50, sm: 70, md: 140 }}
                    color="primary.main"
                    sx={{ mixBlendMode: 'hard-light' }}>
                    {team}
                  </Typography>
                  {openTeamSelect && (
                    <Grow in>
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: { xs: -30, md: -35 },
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          gap: { xs: 4, md: 8 },
                          justifyContent: 'center'
                        }}>
                        {teams.map((year) => (
                          <Typography
                            key={year}
                            onClick={() => setTeam(year)}
                            variant="h4"
                            fontSize={{ xs: '1.2rem', md: '1.5rem' }}
                            sx={{
                              borderBottom:
                                year === team ? `4px solid ${theme.palette.primary.main}` : 'none',
                              cursor: 'pointer',
                              color: trigger ? 'text.primary' : 'white',
                              mixBlendMode: trigger ? 'normal' : 'screen',
                              opacity: 0.8
                            }}>
                            {year}
                          </Typography>
                        ))}
                      </Box>
                    </Grow>
                  )}
                </Box>
              </ClickAwayListener>
              <IconButton
                disabled={team >= 2024}
                onClick={() => setTeam((t) => t + 1)}
                sx={{ height: 80, borderRadius: 4, color: trigger ? 'text.primary' : 'white' }}>
                <KeyboardArrowRightRoundedIcon sx={{ fontSize: '3rem' }} />
              </IconButton>
            </Box>
          </Box>

          <Container
            maxWidth="lg"
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              minHeight: '100vh',
              width: '100%'
            }}>
            <MemberList team={team} chosenGroup={chosenGroup} />
          </Container>
        </Box>
      </Fade>
    </>
  );
};

export default Team;

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FastForwardRoundedIcon from '@mui/icons-material/FastForwardRounded';
import HeightRoundedIcon from '@mui/icons-material/HeightRounded';
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Button,
  Card,
  Container,
  Fade,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useRef } from 'react';
import getProjectBackground from './getProjectImg';
import stetindRocket from '../../assets/images/rocket-projects/2021-stetind/stetind_rocket.webp';
import stetindRocketInner from '../../assets/images/rocket-projects/2021-stetind/stetind_rocket_inner.webp';
import stetindPatch from '../../assets/images/rocket-projects/mission-patches/stetind_patch.png';
import stetindLogo from '../../assets/images/rocket-projects/2021-stetind/stetind_logo.png';

import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { motion, useScroll, useTransform } from 'framer-motion';
import Footer from '../../components/Footer/Footer';
import ReactPlayer from 'react-player';

const StetindCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: '#ffffff00',
  backgroundImage: 'none',
  backdropFilter: 'blur(12px)',
  boxShadow: theme.shadows[0],
  border: 'solid #337DFF 1px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 4
}));

const Stetind = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const { scrollYProgress } = useScroll({ container: ref });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const bottomAnim = useTransform(
    scrollYProgress,
    [0.1, 0.16, 0.42, 0.44],
    [
      isMobile ? '-10vh' : '0%',
      isMobile ? '-100vh' : '-120%',
      isMobile ? '100vh' : '120%',
      isMobile ? '-30vh' : '-30%'
    ]
  );
  const leftAnim = useTransform(
    scrollYProgress,
    [0.1, 0.16, 0.42, 0.44, 0.48, 0.62, 0.64, 0.68, 0.72],
    [
      isMobile ? '70vw' : '20vw',
      isMobile ? '10vw' : '20vw',
      isMobile ? '10vw' : '20vw',
      isMobile ? '-200vw' : '-60vw',
      isMobile ? '-200vw' : '-60vw',
      isMobile ? '-200vw' : '-60vw',
      isMobile ? '-120vw' : '-20vw',
      isMobile ? '-120vw' : '-20vw',
      isMobile ? '20vw' : '20vw'
    ]
  );
  const rotateAnim = useTransform(scrollYProgress, [0.4, 0.44], [0, 90]);
  const scaleAnim = useTransform(
    scrollYProgress,
    [0.1, 0.16, 0.42, 0.44, 0.48],
    [isMobile ? 0.8 : 0.85, isMobile ? 3 : 4, isMobile ? 3 : 4, isMobile ? 3 : 6, isMobile ? 3 : 6]
  );
  const opacityAnim = useTransform(
    scrollYProgress,
    [0.32, 0.36, 0.44, 0.48, 0.76, 0.8],
    [1, 0, 0, 1, 1, 0]
  );
  const opacityAnim2 = useTransform(
    scrollYProgress,
    [0.16, 0.2, 0.24, 0.28, 0.44, 0.48, 0.76, 0.8],
    [0, 1, 1, 0, 0, 1, 1, 0]
  );

  const inner_opacity = useTransform(scrollYProgress, [0.16, 0.2, 0.24, 0.28], [0, 1, 1, 0]);
  const outer_opacity = useTransform(scrollYProgress, [0.24, 0.28, 0.32, 0.36], [0, 1, 1, 0]);
  const avionics_opacity = useTransform(scrollYProgress, [0.44, 0.48, 0.52, 0.56], [0, 1, 1, 0]);
  const avionics2_opacity = useTransform(scrollYProgress, [0.52, 0.56, 0.62, 0.64], [0, 1, 1, 0]);
  const payload_opacity = useTransform(scrollYProgress, [0.62, 0.64, 0.68, 0.72], [0, 1, 1, 0]);
  const recover_opacity = useTransform(scrollYProgress, [0.68, 0.72, 0.76, 0.8], [0, 1, 1, 0]);

  React.useEffect(() => {
    console.log(scrollYProgress);
  }, [scrollYProgress]);

  return (
    <Fade in>
      <Box
        ref={ref}
        sx={{
          height: '100vh',
          overflowY: 'auto',
          '& .MuiTypography-root': {
            pointerEvents: 'none'
          }
        }}>
        <Box
          component="img"
          src={getProjectBackground('Stetind')}
          sx={{
            position: 'fixed',
            top: 0,
            zIndex: -100,
            objectFit: 'cover',
            width: '100%',
            height: '100vh',
            background: 'linear-gradient(0deg, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%)'
          }}
        />
        <motion.img
          src={stetindRocket}
          style={{
            position: 'fixed',
            zIndex: -10,
            bottom: bottomAnim,
            left: leftAnim,
            rotateZ: rotateAnim,
            scale: scaleAnim,
            opacity: opacityAnim,
            height: '90vh',
            objectFit: 'contain',
            objectPosition: 'center'
          }}
        />
        <motion.img
          src={stetindRocketInner}
          style={{
            position: 'fixed',
            zIndex: -10,
            bottom: bottomAnim,
            left: leftAnim,
            rotateZ: rotateAnim,
            scale: scaleAnim,
            opacity: opacityAnim2,
            height: '90vh',
            objectFit: 'contain',
            objectPosition: 'center'
          }}
        />
        <Box>
          <Header
            sx={{
              position: 'static',
              width: '100%',
              zIndex: -10
            }}
          />
          <Grid item xs={12}>
            <Button
              onClick={() => {
                navigate('/Projects');
              }}
              disableRipple
              variant="outlined"
              startIcon={<ArrowBackIosNewIcon />}
              sx={{
                ml: { xs: 3, md: 10 },
                mb: 4,
                borderRadius: 12,
                color: '#337DFF',
                borderColor: '#337DFF'
              }}>
              Back to Projects
            </Button>
          </Grid>
          <Container maxWidth="lg">
            <Grid container sx={{ px: 4, pb: 8 }}>
              <Grid item xs={12} md={4} />
              <Grid item xs={12} md={8}>
                <Grid container spacing={{ xs: 2, md: 4 }}>
                  <Grid item xs={12} component={Stack} flexDirection="column" gap={2}>
                    <Box display="flex" gap={4} alignItems="center">
                      <Box component="img" src={stetindLogo} height={{ xs: 60, md: 120 }} />
                      <Box>
                        <Typography
                          variant="h2"
                          color="white"
                          fontWeight={700}
                          fontSize={{ xs: '1.5rem', md: '3rem' }}
                          sx={{ mt: { xs: 4, md: 0 } }}>
                          PROJECT <br /> STETIND
                        </Typography>
                        <Typography
                          variant="h4"
                          color="white"
                          fontWeight={600}
                          fontSize={{ xs: '1rem', md: '2rem' }}>
                          TEAM 2021
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{ color: 'white' }}
                      fontSize={{ xs: '0.7rem', md: '1rem' }}>
                      Stetind was the first super-sonic rocket built and launched by Propulse NTNU.
                      In June 2021, the team competed with Stetind in the digital Spaceport America
                      Cup 30&apos;K COTS class and won first place in its category, and 2nd place
                      overall among all the 75 participating teams. 4 months later, in October 2021,
                      the team launched Stetind at the European Rocketry Challenge and won the 9000
                      meter SOLID class, and breaking both the EuRoC velocity and altitude records.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <StetindCard sx={{ height: 200 }}>
                      <RocketLaunchRoundedIcon sx={{ fontSize: 60, color: 'white' }} />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'white', textAlign: 'center' }}>
                        Solid Motor
                        <br />
                        (Cesaroni Pro 98)
                      </Typography>
                    </StetindCard>
                  </Grid>
                  <Grid item xs={6}>
                    <StetindCard sx={{ height: 200 }}>
                      <HeightRoundedIcon sx={{ fontSize: 60, color: 'white' }} />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'white' }}>
                        9714 m <br /> Apogee
                      </Typography>
                    </StetindCard>
                  </Grid>
                  <Grid item xs={6}>
                    <StetindCard sx={{ height: 200 }}>
                      <SpeedRoundedIcon sx={{ fontSize: 60, color: 'white' }} />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'white', textAlign: 'center' }}>
                        Mach 1.8 (2205km/h)
                        <br />
                        Top speed
                      </Typography>
                    </StetindCard>
                  </Grid>
                  <Grid item xs={6}>
                    <StetindCard sx={{ height: 200 }}>
                      <FastForwardRoundedIcon sx={{ fontSize: 60, color: 'white' }} />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'white', textAlign: 'center' }}>
                        0-100 km/h
                        <br />
                        in 0.24 seconds
                      </Typography>
                    </StetindCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ height: '600vh' }}></Box>
        <motion.div
          style={{
            position: 'fixed',
            zIndex: -10,
            top: isMobile ? '15vh' : '30%',
            left: '40%',
            right: isMobile ? '10vw' : '10%',
            color: 'white',
            opacity: outer_opacity
          }}>
          <Box sx={{ borderRadius: 4, p: isMobile ? 1 : 2, backdropFilter: 'blur(12px)' }}>
            <Typography variant="h2" fontWeight={600} fontSize={isMobile ? '2rem' : '4rem'}>
              Outer Structure
            </Typography>
            <Typography variant="h4" fontWeight={600} fontSize={isMobile ? '1.5rem' : '2rem'}>
              Von Kármán shape
            </Typography>
            <Typography
              variant="body1"
              maxWidth={500}
              mt={2}
              fontSize={isMobile ? '0.7rem' : '1rem'}>
              The airframe is made out of two segments of filament wound fiber composite, and is
              aerodynamically optimized for supersonic flights up to Mach 1.7, with a Haak series
              shape both on the nose and tail of the rocket. The forward airframe is made of radio
              transparent glass fiber, while the aft is made of carbon fiber. The trapezoidal fins
              ensure a stable flight during the ascent, and the double wedge angle reduces drag from
              the shockwaves and increases lift performance in the supersonic regime.
            </Typography>
          </Box>
        </motion.div>
        <motion.div
          style={{
            position: 'fixed',
            top: isMobile ? '15vh' : '30%',
            left: '40%',
            right: isMobile ? '10vw' : '10%',
            zIndex: -10,
            color: 'white',
            opacity: inner_opacity
          }}>
          <Box sx={{ borderRadius: 4, p: isMobile ? 1 : 2, backdropFilter: 'blur(12px)' }}>
            <Typography variant="h2" fontWeight={600} fontSize={isMobile ? '2rem' : '4rem'}>
              Inner structure
            </Typography>
            <Typography variant="h4" fontWeight={600} fontSize={isMobile ? '1.5rem' : '2rem'}>
              Module based
            </Typography>
            <Typography
              variant="body1"
              maxWidth={500}
              mt={2}
              fontSize={isMobile ? '0.7rem' : '1rem'}>
              The inner structure holds the internal components in place, while some of the
              components, such as the parachute release mechanism, also transfer large forces
              between the load points and the airframe. Material selection for each component has
              carefully been selected to ensure the rocket is as lightweight as possible, while
              still retaining enough strength.
            </Typography>
          </Box>
        </motion.div>
        <motion.div
          style={{
            position: 'fixed',
            top: isMobile ? '10vh' : '25%',
            zIndex: -10,
            left: isMobile ? '10vw' : '40%',
            right: isMobile ? '10vw' : '10%',
            color: 'white',
            opacity: payload_opacity
          }}>
          <Box sx={{ borderRadius: 4, p: isMobile ? 1 : 2, backdropFilter: 'blur(12px)' }}>
            <Typography variant="h2" fontWeight={600} fontSize={isMobile ? '2rem' : '4rem'}>
              Payload
            </Typography>
            <Typography variant="h4" fontWeight={600} fontSize={isMobile ? '1.5rem' : '2rem'}>
              Measuring oscillations
            </Typography>
            <Typography
              variant="body1"
              maxWidth={500}
              mt={2}
              fontSize={isMobile ? '0.7rem' : '1rem'}>
              The 4kg cylindrical payload was developed in partnership with Orbit NTNU, containing a
              set of PCBs from their SelfieSat project. Its purpose was to examine the behavior of
              ferrofluid during flight, with high acceleration, strong vibrations and free fall.
            </Typography>
          </Box>
        </motion.div>
        <motion.div
          style={{
            position: 'fixed',
            top: isMobile ? '10vh' : '10%',
            zIndex: -10,
            left: isMobile ? '10vw' : '40%',
            right: isMobile ? '10vw' : '10%',
            color: 'white',
            opacity: avionics_opacity
          }}>
          <Box sx={{ borderRadius: 4, p: isMobile ? 1 : 2, backdropFilter: 'blur(12px)' }}>
            <Typography variant="h2" fontWeight={600} fontSize={isMobile ? '2rem' : '4rem'}>
              Avionics
            </Typography>
            <Typography variant="h4" fontWeight={600} fontSize={isMobile ? '1.5rem' : '2rem'}>
              Flight computer and electronics
            </Typography>
            <Typography
              variant="body1"
              maxWidth={900}
              mt={2}
              fontSize={isMobile ? '0.7rem' : '1rem'}>
              The avionics system controls and monitors the recovery system using barometers,
              thermometers and cameras, transmitting all this data to the ground station during the
              flight. Both software and printed circuit boards are student researched and developed.{' '}
              <br />
              <br />
              The avionics in the rocket consists of two completely independent flight computers,
              each designed to activate the recovery systems, log data, and track the rocket
              location during and after flight. One of the systems is a commercial solution
              consisting of an Altimax G3 Flight computer, as well as a featherweight GPS tracker.
              The other system is designed, developed, and produced by our team. It mainly consists
              of two PCBs, the final design of which was achieved after iterating an initial design
              twice, each iteration having its own prototype made.
              <br />
              <br />
              In the end, there is a STM32F103 microcontroller running on each PCB, in addition to
              an array of sensors, including barometers, 6DoF IMUs, a GPS as well as some external
              analog sensors measuring certain properties of the recovery system. This suite of
              sensors allows us to develop a comprehensive flight computer capable of accurately
              engaging the recovery system at the optimal time, in additin to giving us lots of
              critical flight data that can be used to assess the performance of the rocket post
              flight.
            </Typography>
          </Box>
        </motion.div>
        <motion.div
          style={{
            position: 'fixed',
            top: isMobile ? '10vh' : '25%',
            zIndex: -10,
            left: isMobile ? '10vw' : '40%',
            right: isMobile ? '10vw' : '10%',
            color: 'white',
            opacity: avionics2_opacity
          }}>
          <Box sx={{ borderRadius: 4, p: isMobile ? 1 : 2, backdropFilter: 'blur(12px)' }}>
            <Typography
              variant="body1"
              maxWidth={900}
              mt={2}
              fontSize={isMobile ? '0.7rem' : '1rem'}>
              The software for the flight computer is also completely developed by our team, written
              in embedded C/C++. It mainly revolves around a Finite State Machine (FSM), which
              allows the flight computer to know where the rocket is during flight using the
              collected sensor data. The sensors themselves have custom written drivers to ensure
              they function the way we need them to. Before the sensor data is used in the FSM it is
              passed through a discrete linear kalman filter. This filter combines pressure data and
              accelerometer data to minimize noise and error in our altitude calculations.
              <br />
              <br />
              Additionally, the rocket has a 5GHz radio transceiver provided by Radionor. This lets
              us transmit live data and the status of our flight computer to the ground during the
              entire flight. It also lets us live stream one of the four cameras onboard the rocket
              to our ground station in full HD 30 fps.
            </Typography>
          </Box>
        </motion.div>
        <motion.div
          style={{
            position: 'fixed',
            top: isMobile ? '5vh' : '10%',
            zIndex: -10,
            left: isMobile ? '10vw' : '10%',
            right: isMobile ? '10vw' : '10%',
            color: 'white',
            opacity: recover_opacity
          }}>
          <Box sx={{ borderRadius: 4, p: isMobile ? 1 : 2, backdropFilter: 'blur(12px)' }}>
            <Typography variant="h2" fontWeight={600} fontSize={isMobile ? '2rem' : '4rem'}>
              Recovery
            </Typography>
            <Typography variant="h4" fontWeight={600} fontSize={isMobile ? '1.5rem' : '2rem'}>
              Dual-event parachute recovery
            </Typography>
            <Typography
              variant="body1"
              maxWidth={1000}
              mt={2}
              fontSize={isMobile ? '0.7rem' : '1rem'}>
              The recovery system incorporates a student researched and developed main parachute
              deployment mechanism with no energetic elements
              <br />
              <br />
              The recovery system has the purpose of ensuring a safe and controlled descent of the
              rocket. To achieve this goal the system features a pressure based separation system
              that splits the rocket allowing a drogue parachute to deploy. The drogue parachute
              allows the rocket to descend with a controlled velocity until it reaches approximately
              457m. At this height the main parachute will be deployed using our main chute
              deployment system.
              <br />
              <br />
              The separation system is a commercial off the shelf system that features a hawk and a
              CO2 canister. The canister is punctured by the hawk and the gas is directed to an
              airtight chamber in the rocket. The pressure builds up until a point where the shear
              screws holding the chamber together brakes in a controlled manner and subsequently the
              rocket is separated.
              <br />
              <br />
              The main chute release mechanism on the other hand is one of our own creations. The
              system is a mechanical system that is designed to be activated by a servo motor and
              ensures a reliable deployment of the main parachute. The system has been designed
              using CAD software, verified through FEM-analysis and lastly validated through
              physical testing.
            </Typography>
          </Box>
        </motion.div>

        <Box sx={{ p: { xs: 4, md: 10 } }}>
          <Grid
            container
            sx={{
              width: '100%',
              border: '2px solid white',
              boxSizing: 'border-box',
              borderRadius: 10,
              minHeight: '90vh',
              overflow: 'hidden',
              backdropFilter: 'blur(6px)'
            }}>
            <Grid
              item
              xs={6}
              md={3}
              display="flex"
              flexDirection="column"
              sx={{ borderRight: '1px solid white' }}>
              <Box sx={{ p: isMobile ? 2 : 4, borderBottom: '1px solid white' }}>
                <Typography
                  variant="h4"
                  color="white"
                  fontWeight={600}
                  fontSize={isMobile ? '1rem' : '2rem'}
                  sx={{ borderRight: '10px solid #337DFF' }}>
                  PROJECT <br /> STETIND
                </Typography>
              </Box>
              <Box
                flex={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ p: isMobile ? 2 : 4, borderBottom: '1px solid white' }}>
                <img src={stetindRocket} style={{ maxHeight: 600 }} />
              </Box>
            </Grid>

            <Grid
              item
              xs={6}
              md={3}
              display="flex"
              flexDirection="column"
              sx={{ borderRight: isMobile ? 'none' : '1px solid white' }}>
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid white', p: isMobile ? 2 : 4 }}>
                <img src={stetindPatch} style={{ width: '100%', aspectRatio: '1/0.95' }} />
              </Box>
              <Box
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid white', p: isMobile ? 2 : 4 }}>
                <RocketLaunchRoundedIcon sx={{ fontSize: 60, color: 'white' }} />
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                  sx={{ color: 'white', textAlign: 'center' }}>
                  Solid Motor
                  <br />
                  (Cesaroni Pro 98)
                </Typography>
              </Box>
              <Box
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid white', p: isMobile ? 2 : 4 }}>
                <HeightRoundedIcon sx={{ fontSize: 60, color: 'white' }} />
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                  sx={{ color: 'white' }}>
                  9714 m <br /> Apogee
                </Typography>
              </Box>
              <Box
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid white', p: isMobile ? 2 : 4 }}>
                <SpeedRoundedIcon sx={{ fontSize: 60, color: 'white' }} />
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                  sx={{ color: 'white', textAlign: 'center' }}>
                  1.8 Mach (2205 km/h)
                  <br />
                  Top speed
                </Typography>
              </Box>
              <Box
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid white', p: isMobile ? 2 : 4 }}>
                <FastForwardRoundedIcon sx={{ fontSize: 60, color: 'white' }} />
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                  sx={{ color: 'white', textAlign: 'center' }}>
                  0-100 km/h
                  <br />
                  in 0.24 seconds
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={6}
              md={3}
              display="flex"
              flexDirection="column"
              sx={{ borderRight: '1px solid white' }}>
              <Box
                flex={2}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid white', p: isMobile ? 2 : 4 }}>
                <Typography
                  variant="h4"
                  color="white"
                  textAlign="center"
                  fontWeight={500}
                  fontSize={isMobile ? '1.25rem' : '2.5rem'}>
                  1st Place
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="white"
                  fontSize={isMobile ? '0.7rem' : '1rem'}>
                  30&apos;K COTS <br /> Spaceport Americap Cup 2021
                </Typography>
              </Box>
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid white', p: isMobile ? 2 : 4 }}>
                <Typography
                  variant="h4"
                  color="white"
                  textAlign="center"
                  fontWeight={500}
                  fontSize={isMobile ? '1.25rem' : '2.5rem'}>
                  2nd Overall
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="white"
                  fontSize={isMobile ? '0.7rem' : '1rem'}>
                  Spaceport America Cup 2021
                </Typography>
              </Box>
              <Box
                flex={2}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid white', p: isMobile ? 2 : 4 }}>
                <Typography
                  variant="h4"
                  color="white"
                  textAlign="center"
                  fontWeight={500}
                  fontSize={isMobile ? '1.25rem' : '2.5rem'}>
                  1st Place
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="white"
                  fontSize={isMobile ? '0.7rem' : '1rem'}>
                  9000m COTS Category <br /> European Rocket Competition (EuRoC) <br /> 2021,
                  Portugal
                </Typography>
              </Box>
              {/* <Box
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ p: isMobile ? 2 : 4 }}>
                <Typography
                  variant="h4"
                  color="white"
                  textAlign="center"
                  fontWeight={500}
                  fontSize={isMobile ? '1.25rem' : '2.5rem'}>
                  3rd Overall
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="white"
                  fontSize={isMobile ? '0.7rem' : '1rem'}>
                  European Rocket Competition (Euroc), Portugal
                </Typography>
              </Box> */}
            </Grid>

            <Grid item xs={6} md={3} display="flex" flexDirection="column">
              <Typography
                variant="h6"
                color="white"
                fontWeight={500}
                fontSize={isMobile ? '1em' : '1.5rem'}
                sx={{ borderBottom: '1px solid white', p: 2 }}>
                EuRoC Launch Video
              </Typography>
              <Box sx={{ aspectRatio: '16/9', borderBottom: '1px solid white', pb: '1px' }}>
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=s6kwxRPH_dk"
                  width="100%"
                  height="100%"
                />
              </Box>

              <Typography
                variant="h6"
                color="white"
                fontWeight={500}
                fontSize={isMobile ? '1em' : '1.5rem'}
                sx={{ borderBottom: '1px solid white', p: 2 }}>
                SA CUP 2021 Presentation
              </Typography>
              <Box sx={{ aspectRatio: '16/9', borderBottom: '1px solid white', pb: '1px' }}>
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=KDSFxY6Z4Ig"
                  width="100%"
                  height="100%"
                />
              </Box>

              <Box
                component="a"
                href="https://drive.google.com/file/d/1PO30FtOnVD1rGjmoYHLKBf0fardLV8s-/view"
                target="_blank"
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  color: 'white',
                  borderBottom: '1px solid white',
                  p: isMobile ? 2 : 4,
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'white',
                    '& .MuiTypography-root': { color: 'black' },
                    '& .MuiSvgIcon-root': { color: 'black' }
                  }
                }}>
                <OpenInNewIcon />
                <Typography
                  variant="h5"
                  color="white"
                  fontWeight={600}
                  fontSize={isMobile ? '1em' : '1.5rem'}>
                  Technical Report
                </Typography>
              </Box>

              <Box
                onClick={() => {
                  navigate('/Projects');
                }}
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  p: isMobile ? 2 : 4,
                  color: 'white',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'white',
                    '& .MuiTypography-root': { color: 'black' },
                    '& .MuiSvgIcon-root': { color: 'black' }
                  }
                }}>
                <ArrowBackIosNewIcon />
                <Typography
                  variant="h5"
                  color="white"
                  fontWeight={600}
                  fontSize={isMobile ? '1em' : '1.5rem'}>
                  Back to PROJECTS
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Footer color="#ffffff" />
        </Box>
      </Box>
    </Fade>
  );
};

export default Stetind;

import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

import {
  Box,
  Card,
  Grid,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  styled
} from '@mui/material';
import { useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const GlassCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: 'rgba(255,255,255, 0.05)',
  backgroundImage: 'none',
  backdropFilter: 'blur(5px)',
  boxShadow: theme.shadows[0],
  gap: 4
}));

const RocketCarousel = (props) => {
  const [slider1, setSlider1] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    accessability: true,
    dots: false,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    onReInit: () => setCurrentSlide(slider1?.innerSlider.state.currentSlide)
  };

  const carouselSlides = props.slides;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const containerRef = useRef(0);
  return (
    <Grid
      container
      ref={containerRef}
      overflow={'hidden'}
      px={4}
      pt={10}
      pb={4}
      maxWidth="lg"
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '1600px'
      }}>
      {/* Left arrow */}
      <Grid item xs={2} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
        <IconButton
          disabled={currentSlide === 0}
          onClick={() => {
            slider1?.slickPrev();
          }}
          sx={{
            height: 80,
            borderRadius: 4
          }}>
          <KeyboardArrowLeftRoundedIcon sx={{ fontSize: '3rem' }} />
        </IconButton>
      </Grid>

      {/* Content */}
      <Grid
        item
        container
        md={8}
        direction="column"
        sx={{
          height: '100%'
        }}>
        {/* Rocket image */}
        <Grid
          item
          md={5}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            width: '100%',
            height: '100%',
            position: 'relative',
            pb: 6
          }}>
          {carouselSlides.map((slide, index) => (
            <img
              key={index}
              src={slide.image}
              alt={slide.title}
              style={{
                width: '95%',
                position: 'absolute',
                opacity: index === currentSlide ? 1 : 0,
                zIndex: index === currentSlide ? 99 : 0,
                transition: 'opacity 0.5s ease-in-out 0s'
              }}
            />
          ))}
        </Grid>
        {/* Text content */}
        <Grid item md={5} width="100%">
          <GlassCard
            sx={{
              // padding: { xs: 2, md: '2% 2%' },
              // p: 2,
              // borderRadius: { xs: 0, md: '20px' },
              borderRadius: '20px',
              height: '100%'
            }}>
            <Slider
              tabindex={0}
              style={{ maxWidth: '100%' }}
              ref={(slider) => setSlider1(slider)}
              {...settings}>
              {carouselSlides.map((slide, index) => (
                <Box key={index} p={2}>
                  <Typography
                    variant="h2"
                    fontWeight={600}
                    // fontSize={isMobile ? '0.7rem' : '2rem'}
                    fontSize={{ xs: 26, md: 30 }}
                    sx={{ color: 'white', my: '.5em' }}>
                    {slide.title}
                  </Typography>
                  <Typography
                    align={'justify'}
                    fontWeight={300}
                    // fontSize={isMobile ? '0.7rem' : '1rem'}
                    fontSize={{ xs: 16, md: 18 }}
                    sx={{ color: 'white', textAlign: 'justify' }}>
                    {slide.content}
                  </Typography>
                </Box>
              ))}
            </Slider>
          </GlassCard>
        </Grid>
        <Grid
          item
          md={1}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            mt: { xs: 2, md: 6 }
          }}>
          {/* Left arrow - if screensize smaller than md */}
          <IconButton
            disabled={currentSlide === 0}
            onClick={() => {
              slider1?.slickPrev();
            }}
            sx={{
              display: { xs: 'flex', md: 'none' },
              height: 80,
              borderRadius: 4
            }}>
            <KeyboardArrowLeftRoundedIcon sx={{ fontSize: '3rem' }} />
          </IconButton>

          {/* Slide dots */}
          <Box
            height="fit-content"
            sx={{
              padding: '10px',
              backdropFilter: { xs: 'none', md: 'blur(6px)' },
              backgroundColor: { xs: 'transparent', md: 'rgba(10,10,10, 0.35)' },
              borderRadius: '100px',
              display: 'flex',
              alignItems: 'center'
              // border: '1px solid white'
            }}>
            {carouselSlides.map((slide, index) => (
              <Box
                key={index}
                onClick={() => {
                  slider1?.slickGoTo(index);
                }}
                sx={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: index === currentSlide ? 'white' : 'rgba(255,255,255,0.2)',
                  borderRadius: '100px',
                  display: 'inline-block',
                  mx: '1vw',
                  cursor: 'pointer'
                }}
              />
            ))}
          </Box>

          {/* Right arrow - if screensize smaller than md */}
          <IconButton
            disabled={currentSlide === carouselSlides.length - 1}
            onClick={() => {
              slider1?.slickNext();
            }}
            sx={{
              display: { xs: 'flex', md: 'none' },
              height: 80,
              borderRadius: 4
            }}>
            <KeyboardArrowRightRoundedIcon sx={{ fontSize: '3rem' }} />
          </IconButton>
        </Grid>
      </Grid>

      {/* Right arrow */}
      <Grid item md={2} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
        <IconButton
          disabled={currentSlide === carouselSlides.length - 1}
          onClick={() => {
            slider1?.slickNext();
          }}
          sx={{
            height: 80,
            borderRadius: 4
          }}>
          <KeyboardArrowRightRoundedIcon sx={{ fontSize: '3rem' }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default RocketCarousel;

import React from 'react';
import { Grid, Typography, Container } from '@mui/material';
import Header from './Header';
import RocketCrash from '../../assets/images/graphical/misc/jalla-rocket-crash.png';

const NotFound = (props) => {
  return (
    // <div>
    //   <Typography variant="h1" component="h2" gutterBottom>
    //     404
    //   </Typography>
    //   <Typography variant="h5" component="h2" gutterBottom>
    //     Page not found
    //   </Typography>
    // </div>
    <>
      <Header />
      <Container maxWidth="lg">
        <Grid container spacing={3} py={2}>
          <Grid item xs={12} md={6} alignItems="center" display="flex" flexDirection="column">
            <Typography variant="h1" color="primary.main" gutterBottom pt={8}>
              404
            </Typography>
            <Typography variant="h5" gutterBottom>
              Houston, we have a problem
            </Typography>
            <Typography variant="h5" gutterBottom>
              Error: Page not found
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={RocketCrash} width="100%" alt="404" />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default NotFound;

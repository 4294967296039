import { Box, Fade, Typography, Container, Grid } from '@mui/material';

import Sleipner from '../../assets/images/rocket-projects/2019-sleipner/Sleipner_faktisk.png';
import PartnerLogo from './PartnerLogo';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import PartnerList from './PartnersList.json';

const Partners = () => {
  const partnerPairing = (partnerList) => {
    const pairList = [];
    for (let i = 0; i < partnerList.length; i += 2) {
      pairList.push(partnerList.slice(i, i + 2));
    }
    return pairList;
  };

  const platinum = PartnerList.tiers
    .find((tier) => tier.tiername === 'platinum')
    .partners.filter((partner) => partner.include === true);

  const gold = PartnerList.tiers
    .find((tier) => tier.tiername === 'gold')
    .partners.filter((partner) => partner.include === true);

  const silver = partnerPairing(
    PartnerList.tiers
      .find((tier) => tier.tiername === 'silver')
      .partners.filter((partner) => partner.include === true)
  );

  const supporters = PartnerList.tiers
    .find((tier) => tier.tiername === 'supporter')
    .partners.filter((partner) => partner.include === true);

  return (
    <>
      <Header />
      <Fade in timeout={300}>
        <Box>
          <Box
            sx={{
              position: 'relative',
              height: '500px',
              width: '100%',
              backgroundImage: `url(${Sleipner})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                backdropFilter: 'grayscale(100%)'
              }}
            />
            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                bgcolor: 'primary.main',
                mixBlendMode: 'hard-light'
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center'
              }}>
              <Container maxWidth="lg">
                <Grid container spacing={4} p={4}>
                  <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="h1"
                      color="white"
                      fontSize={{ xs: 50, sm: 70, md: 90 }}
                      fontWeight={500}>
                      Our partners
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" fontWeight={400} color="white">
                      Propulse NTNU would not be able to develop and launch rockets if it
                      wasn&apos;t for the amazing support the industry and faculties gives us. We
                      are very thankful for our partners and the many ways they support the project!
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>

          <Box sx={{ backgroundColor: 'background.default' }}>
            <Container maxWidth="lg">
              <Box
                sx={{
                  p: { xs: 4, md: 8 },
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 12
                }}>
                <Typography
                  variant="h3"
                  p={{ xs: 3, md: 4 }}
                  fontSize={{ xs: '1.5rem', md: '3rem' }}
                  fontWeight={400}
                  color="white"
                  borderRadius={8}
                  textAlign="center"
                  letterSpacing="0.1em"
                  sx={{
                    background:
                      'linear-gradient(45deg, rgba(215,215,215,1) 0%, rgba(119,198,212,1) 30%, rgba(161,171,201,1) 57%, rgba(215,215,215,1) 90%)'
                  }}>
                  PLATINUM PARTNERS
                </Typography>

                {platinum.map((partner, index) => (
                  <PartnerLogo
                    partner={partner}
                    standardWidth={'90%'}
                    key={index}
                    style={{ paddingBottom: '80px' }}
                  />
                ))}
              </Box>
            </Container>
          </Box>
          <Box sx={{ backgroundColor: 'background.paper' }}>
            <Container maxWidth="md">
              <Box
                sx={{
                  p: { xs: 4, md: 8 },
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 8
                }}>
                <Typography
                  variant="h3"
                  p={{ xs: 3, md: 4 }}
                  fontSize={{ xs: '1.5rem', md: '3rem' }}
                  fontWeight={400}
                  color="white"
                  borderRadius={8}
                  textAlign="center"
                  letterSpacing="0.1em"
                  sx={{
                    background:
                      'linear-gradient(45deg, rgba(255,235,122,1) 0%, rgba(209,173,91,1) 30%, rgba(240,211,112,1) 57%, rgba(212,185,111,1) 90%)'
                  }}>
                  GOLD PARTNERS
                </Typography>

                {gold.map((partner, index) => (
                  <PartnerLogo
                    partner={partner}
                    standardWidth={'90%'}
                    key={index}
                    style={{ paddingBottom: '80px' }}
                  />
                ))}
              </Box>
            </Container>
          </Box>
          <Box sx={{ backgroundColor: 'background.default' }}>
            <Container maxWidth="lg">
              <Box
                sx={{
                  p: { xs: 4, md: 8 },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}>
                <Typography
                  variant="h3"
                  p={{ xs: 3, md: 4 }}
                  fontSize={{ xs: '1.5rem', md: '3rem' }}
                  mb={8}
                  fontWeight={400}
                  color="white"
                  borderRadius={8}
                  textAlign="center"
                  letterSpacing="0.1em"
                  sx={{
                    background:
                      'linear-gradient(45deg, rgba(215,215,215,1) 0%, rgba(198,198,198,1) 30%, rgba(161,161,161,1) 57%, rgba(215,215,215,1) 90%)'
                  }}>
                  SILVER PARTNERS
                </Typography>

                {silver.map((partnerPair, index) =>
                  partnerPair.length === 2 ? (
                    <Grid
                      container
                      spacing={{ xs: 0, md: 4 }}
                      sx={{ p: { xs: 0, md: 4 } }}
                      key={index}>
                      {partnerPair.map((partner, index) => (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{
                            p: { xs: 0, md: 4 },
                            pb: { xs: 12, md: 4 },
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                          key={index}>
                          <PartnerLogo
                            partner={partner}
                            standardWidth={'100%'}
                            style={{
                              paddingBottom: { xs: '80px', md: 0 },
                              width: { xs: '70%', md: '90%' }
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Grid
                      container
                      // spacing={4}
                      key={index}
                      sx={{
                        p: { xs: 0, md: 4 },
                        pb: { xs: 12, md: 4 },
                        display: 'flex',
                        justifyContent: 'center'
                      }}>
                      {partnerPair.map((partner, index) => (
                        <Grid
                          item
                          xs={12}
                          md={4}
                          key={index}
                          p={0}
                          display="flex"
                          justifyContent="center">
                          <PartnerLogo
                            partner={partner}
                            standardWidth={'100%'}
                            style={{
                              paddingBottom: { xs: '80px', md: 0 },
                              width: { xs: '70%', md: '90%' }
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )
                )}
              </Box>
            </Container>
          </Box>
          <Box sx={{ backgroundColor: 'background.paper' }}>
            <Container maxWidth="lg">
              <Box
                sx={{
                  p: { xs: 4, md: 8 },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}>
                <Typography
                  minWidth="fit-content"
                  p={{ xs: 3, md: 4 }}
                  mb={8}
                  fontSize={{ xs: '1.5rem', md: '3rem' }}
                  fontWeight={400}
                  color="white"
                  borderRadius={8}
                  textAlign="center"
                  letterSpacing="0.1em"
                  sx={{
                    bgcolor: 'primary.main'
                  }}>
                  SUPPORTERS
                </Typography>
                <Grid container spacing={8} p={4}>
                  {supporters.map((partner, index) => (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      key={index}
                      display="flex"
                      alignItems="center"
                      justifyContent="center">
                      <PartnerLogo
                        partner={partner}
                        standardWidth={'100%'}
                        style={{
                          paddingBottom: { xs: '80px', md: 0 },
                          width: { xs: '70%', md: '90%' }
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Container>
          </Box>
          <Footer />
        </Box>
      </Fade>
    </>
  );
};

export default Partners;

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import HeightRoundedIcon from '@mui/icons-material/HeightRounded';
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import StraightenRoundedIcon from '@mui/icons-material/StraightenRounded';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Button,
  Card,
  Container,
  Fade,
  Grid,
  styled,
  Typography,
  CardMedia,
  useTheme,
  useMediaQuery
} from '@mui/material';
import React, { useEffect } from 'react';
import getProjectBackground from './getProjectImg';
import SleipnerRocket from '../../assets/images/rocket-projects/2019-sleipner/sleipner_rocket.webp';
import LaunchPrep from '../../assets/images/rocket-projects/2019-sleipner/Sleipner_SAC.jpg';
import SleipnerLaunchImage from '../../assets/images/rocket-projects/2019-sleipner/Sleipner-launch.webp';
import SleipnerPatch from '../../assets/images/rocket-projects/mission-patches/sleipner_patch.png';
import SleipnerLaunch from '../../assets/videos/sleipner-launch.mp4';

import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './ProjectAnimate.css';
import { motion, useScroll, useTransform } from 'framer-motion';
import ReactPlayer from 'react-player';

const SleipnerCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: 'rgba(0,0,0, 0.1)',
  backgroundImage: 'none',
  backdropFilter: 'blur(12px)',
  boxShadow: theme.shadows[0],
  border: 'solid #fc7703 1px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 4
}));

const Sleipner = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const gridItems = document.querySelectorAll(
      '.animate-slide-in, .animate-slide-right, .animate-slide-left'
    );
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        } else {
          entry.target.classList.remove('animate');
        }
      });
    });
    gridItems.forEach((item) => observer.observe(item));

    console.log('useEffect');

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Fade in>
      <Box>
        <Box
          component="img"
          src={getProjectBackground('Sleipner')}
          sx={{
            position: 'fixed',
            top: 0,
            zIndex: -10,
            objectFit: 'cover',
            width: '100%',
            height: '100vh',
            background: 'linear-gradient(0deg, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%)'
          }}
        />

        <Box sx={{ position: 'relative' }}>
          <Header
            sx={{
              width: '100%',
              zIndex: 1000
            }}
          />
          <Grid item xs={12}>
            <Button
              onClick={() => {
                navigate('/Projects');
              }}
              disableRipple
              variant="outlined"
              // color="inherit"
              startIcon={<ArrowBackIosNewIcon />}
              sx={{
                ml: { xs: 3, md: 10 },
                mb: 4,
                borderRadius: 12,
                color: '#fc7703',
                borderColor: '#fc7703'
              }}>
              Back to Projects
            </Button>
          </Grid>
          <Container maxWidth="lg">
            <Grid container sx={{ px: 4, pb: 8 }}>
              <Grid item xs={4} md={4} />
              <Grid item xs={8} md={8}>
                <Grid container spacing={{ xs: 2, md: 4 }}>
                  <Grid
                    item
                    xs={12}
                    component={Stack}
                    gap={2}
                    sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant="h2"
                      color="white"
                      fontWeight={700}
                      fontSize={{ xs: '1.5rem', md: '3rem' }}
                      sx={{ mt: { xs: 4, md: 0 } }}>
                      PROJECT <br /> SLEIPNER
                    </Typography>
                    <Typography
                      variant="h4"
                      color="white"
                      fontWeight={600}
                      fontSize={{ xs: '1rem', md: '2rem' }}>
                      TEAM 2019
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ color: 'white' }}
                      fontSize={{ xs: '0.8rem', md: '1rem' }}>
                      Sleipner participated in Spaceport America Cup&apos;s 10k COTS category in
                      2019, and was the first rocket built and developed by Propulse NTNU. Sleipner
                      was also the first Nordic rocket to launch at Spaceport America. The 3U
                      CubeSat payload was a collaborative project with Orbit NTNU, where 1 unit
                      consisted of an information gathering and processing system developed by
                      Propulse NTNU, and the remaining 2 units were developed by Orbit NTNU. Orbit
                      NTNU&apos;s unit contained several components of their SelfieSat satellite to
                      be flight and stress tested during Sleipner&apos;s flight, with emphasis on
                      vibration and temperature endurance.
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <SleipnerCard sx={{ height: { xs: 140, md: 200 } }}>
                      <RocketLaunchRoundedIcon sx={{ fontSize: 60, color: 'white' }} />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'white', textAlign: 'center' }}>
                        Solid Motor
                        <br />
                        (Cesaroni Pro 98)
                      </Typography>
                    </SleipnerCard>
                  </Grid>
                  <Grid item xs={6}>
                    <SleipnerCard sx={{ height: { xs: 140, md: 200 } }}>
                      <HeightRoundedIcon sx={{ fontSize: 60, color: 'white' }} />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'white' }}>
                        ~1500 m <br /> Apogee
                      </Typography>
                    </SleipnerCard>
                  </Grid>
                  <Grid item xs={6}>
                    <SleipnerCard sx={{ height: { xs: 140, md: 200 } }}>
                      <SpeedRoundedIcon sx={{ fontSize: 60, color: 'white' }} />
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'white', textAlign: 'center' }}>
                        ~Mach 0.8 (1000 km/h)
                        <br />
                        Top speed
                      </Typography>
                    </SleipnerCard>
                  </Grid>
                  <Grid item xs={6}>
                    <SleipnerCard sx={{ height: { xs: 140, md: 200 } }}>
                      <StraightenRoundedIcon sx={{ fontSize: 60, color: 'white' }} />

                      <Typography
                        variant="h6"
                        fontWeight={600}
                        fontSize={isMobile ? '0.7rem' : '1rem'}
                        sx={{ color: 'white', textAlign: 'center' }}>
                        2.4 m <br /> Length
                      </Typography>
                    </SleipnerCard>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>

          <Container
            maxWidth="lg"
            sx={{
              height: { xs: '140vh', sm: '100dvh', md: '100dvh' },
              width: '100%',
              position: 'absolute',
              // overflow: { xs: 'hidden', md: 'visible' },
              overflow: 'hidden',
              bottom: 0,
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: { xs: 'end', md: 'center' }
            }}>
            <Grid container>
              <Grid item xs={4} />
              <Grid item xs={8}>
                <Typography
                  // className="animate-slide-left"
                  variant="h2"
                  fontWeight={700}
                  fontSize={{ xs: '2rem', md: '4rem' }}
                  color="white"
                  sx={{ mt: 10 }}>
                  Engineering breakdown
                </Typography>
                <Typography
                  // className="animate-slide-left"
                  variant="body1"
                  color="white"
                  sx={{ mt: 4 }}
                  fontSize={{ xs: '0.8rem', md: '1rem' }}>
                  Sleipner featured a length of 2.4 m and a diameter of 18.5 cm. It had a liftoff
                  weight of 18.8 kg and a maximum velocity of approximately 1000 km/h, and utilized
                  a COTS Cesaroni Pro-98 4G motor for propulsion.
                  <br />
                  <br />
                  The airframe sections were constructed using carbon fiber, providing strength and
                  durability. The nose cone was made of fiberglass, while the couplers and boat tail
                  were aluminum. The fins were a combination of aluminum, carbon fiber, and foam
                  core, enhancing stability and control.
                  <br />
                  <br />
                  To control the rocket&apos;s apogee, an air brake system was implemented between
                  the upper and lower airframe sections. The recovery system incorporated a dual
                  deployment system with drogue and main parachutes, safely located inside and below
                  the nose cone. Drogue deployment utilized Hawk CO2 systems, while the main chute
                  deployed through a 3-ring release system.
                  <br />
                  <br />
                  Sleipner featured an SRAD flight computer, integrating various electronic systems.
                  A custom PCB was developed, equipped with a 32-bit processor, IMU and barometer
                  sensors, GPS, Bluetooth, SD card, and a radio transmitter. Real-time flight data
                  was received and displayed using specially designed ground station software.
                </Typography>
              </Grid>
            </Grid>
          </Container>

          {isMobile && <Box height="40vh" />}

          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              height: { xs: '100vh', md: '100dvh' },
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              zIndex: -5
            }}>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={4} display="flex" justifyContent="center">
                  <img
                    src={SleipnerRocket}
                    style={{
                      height: isMobile ? '60vh' : '70vh',
                      objectFit: 'contain',
                      objectPosition: 'center'
                    }}
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>

        <Box
          sx={{
            // height: { xs: 'fit-content', sm: '100vh' },
            height: { xs: 'fit-content', md: '100vh' },
            // maxHeight: '100vh',
            minHeight: '100vh',
            width: '100%',
            overflow: 'hidden'
          }}>
          <Grid container direction="row-reverse" height="100%">
            <Grid item xs={0} md={1} />

            <Grid
              item
              sm={12}
              md={5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center'
              }}>
              <Typography
                // className="animate-slide-left"
                variant="h2"
                fontWeight={700}
                color="white"
                px={{ xs: 2, md: 0 }}
                fontSize={{ xs: '2rem', md: '4rem' }}
                sx={{ mt: 10 }}>
                Competition Results
              </Typography>
              <Typography
                // className="animate-slide-left"
                variant="body1"
                color="white"
                fontSize={{ xs: '0.8rem', md: '1rem' }}
                sx={{ mt: 4, px: { xs: 2, md: 0 } }}>
                Sleipner was launched successfully and had a stable flight until approximately 1500
                m altitude. Instabilities occurring near the maximum velocity of the rocket, likely
                caused by fin fluttering, caused the nose cone to be ejected prematurely, and the
                rocket to quickly decelerate. The rapid ejection of the recovery system at such a
                high velocity caused the chords of the parachute systems to fail, and the rocket
                fell back to the ground without a parachute system. Sleipner was retrieved from the
                launch site afterwards, and video files and flight data could be assessed to further
                analyse the flight.
                <br />
                <br />
                Out of the 121 teams participating at Spaceport America Cup 2019, Propulse was one
                of 20 teams to be selected for the poster sessions, where the airbrake control
                system was presented. Sleipner received 188.5/200 points for the project technical
                report and 210/240 points for design implementation.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              position="relative"
              py={{ xs: 4, md: 0 }}
              sx={{
                position: 'relative',
                py: { xs: 4, md: 0 },
                display: 'flex',
                justifyContent: 'space-around'
              }}>
              <Box
                // className="animate-slide-right"
                component="img"
                src={SleipnerLaunchImage}
                // display={{ xs: 'none', md: 'initial' }}
                sx={{
                  // width: '35vw',
                  // height: '45vh',
                  width: { xs: '90vw', sm: '40vw', md: '35vw' },
                  height: { xs: '30vh', sm: '40vh', md: '45vh' },
                  objectFit: 'cover',
                  position: { xs: 'initial', md: 'absolute' },
                  // top: '15%',
                  // left: '0%',
                  top: { xs: 'initial', md: '15%' },
                  left: { xs: 'initial', md: '0%' },
                  borderRadius: '20px',
                  boxShadow: 'none',
                  overflow: 'hidden',
                  zIndex: '11'
                }}
              />
              <Box
                // className="animate-slide-right"
                component="img"
                src={LaunchPrep}
                sx={{
                  display: { xs: 'none', sm: 'initial' },
                  // width: '30vw',
                  // height: '35vh',
                  width: { xs: '40vw', md: '30vw' },
                  height: { xs: '40vh', md: '35vh' },
                  objectFit: 'cover',
                  position: { sm: 'initial', md: 'absolute' },
                  // top: '55%',
                  // left: '20%',
                  top: { xs: 'initial', md: '55%' },
                  left: { xs: 'initial', md: '20%' },
                  borderRadius: '20px',
                  boxShadow: 'none',
                  overflow: 'hidden',
                  zIndex: '11'
                }}
              />
            </Grid>
            <Grid item xs={0} md={1} />
          </Grid>
        </Box>

        <Box sx={{ p: { xs: 4, md: 10 } }}>
          <Grid
            container
            sx={{
              width: '100%',
              border: '2px solid white',
              boxSizing: 'border-box',
              borderRadius: 10,
              minHeight: '90vh',
              overflow: 'hidden',
              backdropFilter: 'blur(6px)'
            }}>
            <Grid
              item
              xs={6}
              md={4}
              display="flex"
              flexDirection="column"
              sx={{ borderRight: '1px solid white' }}>
              <Box sx={{ p: isMobile ? 2 : 4, borderBottom: '1px solid white' }}>
                <Typography
                  variant="h4"
                  color="white"
                  fontWeight={600}
                  fontSize={isMobile ? '1rem' : '2rem'}
                  sx={{ borderRight: '10px solid #fc7703' }}>
                  PROJECT <br /> SLEIPNER
                </Typography>
              </Box>
              <Box
                flex={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ p: isMobile ? 2 : 4, borderBottom: '1px solid white' }}>
                <img src={SleipnerRocket} style={{ maxHeight: 600 }} />
              </Box>
            </Grid>

            <Grid
              item
              xs={6}
              md={4}
              display="flex"
              flexDirection="column"
              sx={{ borderRight: isMobile ? 'none' : '1px solid white' }}>
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid white', p: isMobile ? 2 : 4 }}>
                <img
                  src={SleipnerPatch}
                  style={{ height: isMobile ? 140 : 200, aspectRatio: '0.9/1' }}
                />
              </Box>
              <Box
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid white', p: isMobile ? 2 : 4 }}>
                <RocketLaunchRoundedIcon sx={{ fontSize: 60, color: 'white' }} />
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                  sx={{ color: 'white', textAlign: 'center' }}>
                  Solid Motor
                  <br />
                  (Cesaroni Pro 98)
                </Typography>
              </Box>
              <Box
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid white', p: isMobile ? 2 : 4 }}>
                <HeightRoundedIcon sx={{ fontSize: 60, color: 'white' }} />
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                  sx={{ color: 'white' }}>
                  ~1500 m <br /> Apogee
                </Typography>
              </Box>
              <Box
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid white', p: isMobile ? 2 : 4 }}>
                <SpeedRoundedIcon sx={{ fontSize: 60, color: 'white' }} />
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                  sx={{ color: 'white', textAlign: 'center' }}>
                  ~Mach 0.8 (1000 km/h)
                  <br />
                  Top speed
                </Typography>
              </Box>
              <Box
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ borderBottom: '1px solid white', p: isMobile ? 2 : 4 }}>
                <StraightenRoundedIcon sx={{ fontSize: 60, color: 'white' }} />
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={isMobile ? '0.7rem' : '1rem'}
                  sx={{ color: 'white', textAlign: 'center' }}>
                  2.4 m <br /> Length
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={4} display="flex" flexDirection="column">
              <Typography
                variant="h6"
                color="white"
                fontWeight={500}
                fontSize={isMobile ? '1em' : '1.5rem'}
                sx={{ borderBottom: '1px solid white', p: 2 }}>
                SAC Launch
              </Typography>
              <Box sx={{ aspectRatio: '16/9', borderBottom: '1px solid white', pb: '1px' }}>
                {/* <ReactPlayer url={SleipnerLaunch} width="100%" height="100%" /> */}
                <video width="100%" height="100%" controls>
                  <source src={SleipnerLaunch} type="video/mp4" />
                </video>
              </Box>
              <Box
                component="a"
                href="https://drive.google.com/file/d/1r4fOae0K507HIbz2k6hXCL0QyJLTKusd/view"
                target="_blank"
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  borderBottom: '1px solid white',
                  p: isMobile ? 2 : 4,
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'white',
                    color: 'black',
                    '& .MuiTypography-root': { color: 'black' },
                    '& .MuiSvgIcon-root': { color: 'black' }
                  }
                }}>
                <OpenInNewIcon sx={{ color: 'white' }} />
                <Typography
                  variant="h5"
                  color="white"
                  fontWeight={600}
                  fontSize={isMobile ? '1em' : '1.5rem'}>
                  Technical Report
                </Typography>
              </Box>

              <Box
                onClick={() => {
                  navigate('/Projects');
                }}
                flex={1}
                gap={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  p: isMobile ? 2 : 4,
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'white',
                    '& .MuiTypography-root': { color: 'black' },
                    '& .MuiSvgIcon-root': { color: 'black' }
                  }
                }}>
                <ArrowBackIosNewIcon sx={{ color: 'white' }} />
                <Typography
                  variant="h5"
                  color="white"
                  fontWeight={600}
                  fontSize={isMobile ? '1em' : '1.5rem'}>
                  Back to PROJECTS
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Footer color="#ffffff" />
        </Box>
      </Box>
    </Fade>
  );
};

export default Sleipner;

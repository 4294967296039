import {
  Box,
  Fade,
  Typography,
  Container,
  Divider,
  Grid,
  Link,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme
} from '@mui/material';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import PropulseCard from '../../components/core/Universal/PropulseCard';
import LaunchSpectators from '../../assets/images/rocket-projects/2022-birkeland/Launch-spectators.JPG';
import VippsQR from '../../assets/images/graphical/misc/Kiosk-vipps-QR.png';
import VippsLogo from '../../assets/images/graphical/other-logos/vipps-rgb-white.svg';
import GofundmeLogo from '../../assets/images/graphical/other-logos/GoFundMe-logo-white.svg';
import PencilIcon from '@mui/icons-material/DriveFileRenameOutline';
import FireExtinguisherIcon from '@mui/icons-material/FireExtinguisher';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ConstructionIcon from '@mui/icons-material/Construction';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import FlightIcon from '@mui/icons-material/Flight';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import SavingsIcon from '@mui/icons-material/Savings';
import BirkelandLogos from '../../assets/images/rocket-projects/2022-birkeland/birkeland-logos-zoomed.JPG';

const Donation = () => {
  const theme = useTheme();
  return (
    <>
      <Header />
      <Fade in timeout={300}>
        <Box>
          {/* <Box
            sx={{
              py: 10,
              height: '250px',
              backgroundImage: `url(${LaunchSpectators})`,
              backgroundSize: 'cover',
              backgroundPosition: '100% 75%',
              backgroundRepeat: 'no-repeat'
            }}></Box> */}
          <Box
            sx={{
              position: 'relative',
              height: '400px',
              width: '100%',
              backgroundImage: `url(${LaunchSpectators})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                backdropFilter: 'grayscale(100%)'
              }}
            />
            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                bgcolor: 'primary.main',
                mixBlendMode: 'hard-light'
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center'
              }}>
              <Container maxWidth="lg">
                <Grid container spacing={4} p={4}>
                  <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="h1"
                      color="white"
                      fontSize={{ xs: 50, sm: 70, md: 90 }}
                      fontWeight={500}>
                      Support us
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="bosy1" fontWeight={400} color="white">
                      Rockets are hard and certainly not free. Our projects don&apos;t generate any
                      sales or monetary gains, and we solely rely on support from partners large and
                      small.
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>
          <Container maxWidth="md">
            <PropulseCard sx={{ width: '100%', p: 5, mt: 5 }}>
              <Typography variant="b1">
                <b>Your support will go to either of the following:</b>
                <List dense>
                  <ListItem>
                    <ListItemIcon>
                      <ConstructionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Equipment, materials and production" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FireExtinguisherIcon />
                    </ListItemIcon>
                    <ListItemText primary="Propellants & fire supression systems" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CandlestickChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Ground support systems" />
                  </ListItem>
                </List>
                <br />
                <b>In return for donations</b> you can put your signature / company logo on the
                airframe of our newest rocket that will be launched this summer! Just fill out your
                infomation in{' '}
                <Link target="_blank" href="https://forms.gle/zZnyxJsfPmZvLNna7">
                  this form
                </Link>
                !
                {/* We still welcome donations, but Bifrost&apos;s painting work is completed, so we
                unfortunately won&apos;t be able to add any more signatures and logos to the rocket. */}
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <PencilIcon height="2em" />
                  </ListItemIcon>

                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="b1">
                        <b>300 NOK</b> or higher
                        <br />
                      </Typography>
                    }
                    secondary={
                      theme.palette.mode === 'dark' ? (
                        <Typography
                          fontSize="0.95rem"
                          fontWeight="400"
                          lineHeight="1.45"
                          color="rgba(255, 255, 255, 0.7)">
                          Get your signature on the rocket
                        </Typography>
                      ) : (
                        <Typography
                          fontSize="0.95rem"
                          fontWeight="400"
                          lineHeight="1.45"
                          color="rgba(0, 0, 0, 0.6)">
                          Get your signature on the rocket
                        </Typography>
                      )
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FireExtinguisherIcon />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="b1">
                        <b>1000 NOK</b> or higher
                        <br />
                      </Typography>
                    }
                    secondary={
                      theme.palette.mode === 'dark' ? (
                        <Typography
                          fontSize="0.95rem"
                          fontWeight="400"
                          lineHeight="1.45"
                          color="rgba(255, 255, 255, 0.7)">
                          Get your company logo printed on the rocket
                        </Typography>
                      ) : (
                        <Typography
                          fontSize="0.95rem"
                          fontWeight="400"
                          lineHeight="1.45"
                          color="rgba(0, 0, 0, 0.6)">
                          Get your company logo printed on the rocket
                        </Typography>
                      )
                    }
                  />
                </ListItem>
              </List>
              <Box
                component="img"
                src={BirkelandLogos}
                sx={{
                  width: '100%',
                  my: 3,
                  borderRadius: '20px',
                  display: { xs: 'none', sm: 'initial' }
                }}
              />
              <br />
              <Typography variant="b1">
                Curious on other ways to get involved in our projects? Contact us at{' '}
                <Link href="mailto:contact@propulsentnu.no">contact@propulsentnu.no</Link>
              </Typography>
            </PropulseCard>
            <Box sx={{ my: 3, display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h4" color="primary.main" textAlign="center">
                Donation methods:
              </Typography>
            </Box>
            <Grid container spacing={2} sx={{ my: 3 }}>
              <Grid item xs={12} sm={4}>
                <PropulseCard sx={{ width: '100%', border: 'solid #02a95c' }}>
                  <Box
                    sx={{
                      bgcolor: '#02a95c',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '64px'
                    }}>
                    <Box component="img" src={GofundmeLogo} sx={{ height: '50px' }} />
                  </Box>
                  <Divider />
                  <CardContent>
                    <Typography variant="b1">
                      Support us over at our GoFundMe page by clicking the link below.
                      <br />
                      <br />
                      <Link target="_blank" href="https://gofund.me/18efe47b">
                        Support Project 2024
                      </Link>
                    </Typography>
                  </CardContent>
                </PropulseCard>
              </Grid>
              <Grid item xs={12} sm={4}>
                <PropulseCard sx={{ width: '100%', border: 'solid #FF5B24' }}>
                  {/* <CardContent>
                    <Typography variant="h5">Vipps</Typography>
                  </CardContent> */}
                  <Box
                    sx={{
                      bgcolor: '#FF5B24',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '64px'
                    }}>
                    <Box component="img" src={VippsLogo} sx={{ height: '50px' }} />
                  </Box>
                  <Divider />
                  <CardContent>
                    <Typography variant="b1">
                      Vipps us at #770171 or scan the QR code below.
                    </Typography>
                    <Box
                      mt={2}
                      component="img"
                      src={VippsQR}
                      sx={{ width: '100%', borderRadius: '20px' }}
                    />
                  </CardContent>
                </PropulseCard>
              </Grid>
              <Grid item xs={12} sm={4}>
                <PropulseCard sx={{ width: '100%' }}>
                  <CardContent>
                    <Typography variant="h5">Bank transfer</Typography>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Typography variant="b1">
                      Transfer directly to our bank account <br />
                      <br />
                      <b>Account number:</b> 1506.69.97488
                    </Typography>
                  </CardContent>
                </PropulseCard>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Fade>
      <Footer />
    </>
  );
};

export default Donation;

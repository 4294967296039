import { Box, Typography } from '@mui/material';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

import React from 'react';

function AboutHistoryYear(props) {
  return (
    <Box
      onMouseEnter={(e) => {
        props.setYear(props.cardyear);
        props.setOpen(true);
      }}
      onClick={(e) => {
        props.setYear(props.cardyear);
        props.setOpen(true);
      }}
      onTouchStart={(e) => {
        props.setYear(props.cardyear);
        props.setOpen(true);
      }}
      sx={{
        p: 2,
        userSelect: 'none',
        cursor: 'pointer',
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: {
          xs: props.reverse ? 'row-reverse' : 'row',
          md: props.reverse ? 'column-reverse' : 'column'
        },
        bgcolor: props.year === props.cardyear ? 'primary.main' : 'none',
        transform: props.year === props.cardyear ? 'scale(1.2)' : 'none',
        transition: 'ease 0.2s'
      }}>
      <Typography
        variant="h5"
        fontWeight={500}
        sx={{ color: props.year === props.cardyear ? 'primary.contrastText' : 'text.default' }}>
        {props.cardyear}
      </Typography>
      <ArrowDropDownRoundedIcon
        fontSize="large"
        sx={{
          color: props.year === props.cardyear ? 'secondary.main' : 'text.default',
          rotate: {
            xs: props.reverse ? '90deg' : '-90deg',
            md: props.reverse ? '180deg' : 'none'
          }
        }}
      />
    </Box>
  );
}

export default AboutHistoryYear;

import { Box, Card, CardContent, Chip, Container, Grid, styled, Typography } from '@mui/material';

import React from 'react';

import Footer from '../../components/Footer/Footer';
import MemberCard from './MemberCard';
// import * as Members from './MemberList.json';
import Members from './MemberList.json';

const PropulseCard = styled(Card)`
  border-radius: 20px;
  box-shadow: none;
  overflow: hidden;
`;

// const MembersString = JSON.stringify(Members);
// const Teams = JSON.parse(MembersString).teams;
// console.log(Members.teams);
const Teams = Members.teams;

const MemberList = (props) => {
  const [chosenGroupState, setChosenGroupState] = React.useState(() => {
    if (props.chosenGroup === undefined) return 'board';
    return props.chosenGroup;
  });

  React.useEffect(() => {
    setChosenGroupState('board');
  }, [props.team]);

  var teamGroups;
  var teamChosenGroup;
  try {
    teamGroups = Teams.find((team) => parseInt(team.teamYear) === parseInt(props.team)).groups;
    teamChosenGroup = teamGroups.find((group) => group.groupUrl === chosenGroupState);
    if (teamChosenGroup === undefined) {
      throw new Error('Group not found');
    }
  } catch (error) {
    teamGroups = Teams.find((team) => parseInt(team.teamYear) === parseInt(2022)).groups;
    teamChosenGroup = teamGroups.find((group) => group.groupUrl === 'board');
  }

  return (
    <Container sx={{ position: 'absolute', zIndex: 10 }}>
      <PropulseCard
        sx={{
          width: '100%',
          height: 'max-content',
          minHeight: '70vh',
          transition: 'ease-in 0.3s'
        }}>
        <CardContent
          sx={{
            display: 'flex',
            p: { xs: 2, md: 4 },
            justifyContent: 'center',
            gap: 1,
            flexWrap: 'wrap'
          }}>
          {teamGroups.map((group) => (
            <Chip
              key={group.groupName}
              label={group.groupName}
              color={chosenGroupState === group.groupUrl ? 'primary' : 'default'}
              sx={{
                px: { xs: 0, md: 2 },
                height: { xs: 30, md: 46 },
                borderRadius: 24,
                fontSize: { xs: 14, md: 18 }
              }}
              onClick={() => setChosenGroupState(group.groupUrl)}
            />
          ))}
        </CardContent>
        {teamChosenGroup.groupLead.name ? (
          <>
            <Grid container component={CardContent} spacing={2} sx={{ justifyContent: 'center' }}>
              <Grid item xs={12} sm={6} md={4} sx={{ height: { xs: 560, sm: 500, md: 460 } }}>
                <MemberCard
                  member={teamChosenGroup.groupLead}
                  img={teamChosenGroup.groupLead.img}
                  index={1}
                  key={teamChosenGroup.groupLead.name}
                />
              </Grid>
            </Grid>
            {teamChosenGroup.subGroups.map((subGroup, subIndex) => (
              <Box
                key={subGroup.subGroupName}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1
                }}>
                <Typography
                  variant="h4"
                  fontWeight={600}
                  color="primary.main"
                  sx={{
                    textAlign: 'center',
                    mt: 4,
                    bgcolor: 'primary.main',
                    color: 'white',
                    py: 2,
                    px: 4,
                    mx: 2,
                    borderRadius: { xs: 4, md: 6 },
                    fontSize: { xs: '1.2rem', md: '1.5rem' }
                  }}>
                  {subGroup.subGroupName}
                </Typography>
                <Grid container component={CardContent} spacing={2}>
                  {subGroup.subGroupMembers.map((member, index) => (
                    <Grid
                      item
                      key={member.name}
                      xs={12}
                      sm={6}
                      md={4}
                      sx={{ height: { xs: 560, sm: 500, md: 460 } }}>
                      <MemberCard member={member} img={member.img} index={index} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
          </>
        ) : (
          <Grid container component={CardContent} spacing={2}>
            {teamChosenGroup.groupMembers.map((member, index) => (
              <Grid
                item
                key={member.name + props.team}
                xs={12}
                sm={6}
                md={4}
                sx={{ height: { xs: 560, sm: 500, md: 460 } }}>
                <MemberCard member={member} img={member.img} index={index} />
              </Grid>
            ))}
          </Grid>
        )}
      </PropulseCard>
      <Footer />
    </Container>
  );
};

export default MemberList;

import './Penumbra.css';

import {
  Box,
  Button,
  Card,
  Fade,
  Grid,
  Icon,
  useTheme,
  CardMedia,
  Typography,
  IconButton,
  styled
} from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import GitHubIcon from '@mui/icons-material/GitHub';
import CodeIcon from '@mui/icons-material/Code';
import RocketIcon from '@mui/icons-material/RocketLaunch';
import MathIcon from '@mui/icons-material/Functions';
import FileIcon from '@mui/icons-material/Folder';
import PerformanceIcon from '@mui/icons-material/Speed';
import AnalyzeIcon from '@mui/icons-material/BarChart';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import penumbra_card1 from '../../assets/images/penumbra/penumbra_logo.png';
import penumbra_card2 from '../../assets/images/penumbra/penumbra_showcase.svg';
import PSC_Simulation from '../../assets/images/penumbra/penumbra_sc1.svg';
import PSC_Traj from '../../assets/images/penumbra/penumbra_sc2.svg';
import PSC_Aero from '../../assets/images/penumbra/penumbra_sc3.svg';
import PSC_Code from '../../assets/images/penumbra/penumbra_sc4.svg';
import penumbraLogo from '../../assets/images/penumbra/penumbra_logo.png';
import deco from '../../assets/images/penumbra/deco.svg';

import penumbra_bg from '../../assets/images/backgrounds/penumbra_header.jpg';

const Specifications = [
  {
    name: 'Code Efficiency',
    icon: CodeIcon,
    image: PSC_Code,
    description:
      'Penumbra allows real-time flight simulation and concurrent simulations using our self-developed C++ library.'
  },
  {
    name: 'Analytic Aerodynamics',
    icon: RocketIcon,
    image: PSC_Aero,
    description:
      'Employing both empirical methods and computed drag coefficients to accurately estimate the flight trajectory of a rocket.'
  },
  {
    name: 'File Handling',
    icon: FileIcon,
    image: PSC_Simulation,
    description:
      'Handling large amounts of flight data and visualizing it for easy post processing.'
  },
  {
    name: 'Stochastic Analysis',
    icon: MathIcon,
    image: PSC_Traj,
    description:
      'Using stochastic methods to create an  ensemble of variations the rocket can have to accurately reflect real world noise and systematic inaccuracies.'
  }
];

const TimelineItems = [
  {
    year: '2020',
    items: [
      {
        item: 'Precursors',
        description:
          'Members of other groups in Propulse NTNU who are working on trajectory and drag simulations propose a dedicated group for developing an in-house trajectory simulator. A prototype is created in Python.'
      }
    ]
  },
  {
    year: '2021',
    items: [
      {
        item: 'Concept and skeleton',
        description:
          'To make a performant, distributable and cross-compilable application, the Penumbra team decides to switch to a back-end in C++ coupled with a front-end in React and Electron.'
      }
    ]
  },
  {
    year: '2022',
    items: [
      {
        item: 'Backend core functionality',
        description:
          'Most of the core functionality of the back-end is completed, such as differential solvers, file handling, rocket part assembly and statistical ensemble simulations.'
      },
      {
        item: 'Front-end core functionality',
        description:
          'The front-end is presented as a stylish, easy-to-use interface for the back-end. Visualizing the results of ensemble simulations in an interactive way.'
      },
      {
        item: 'Front-end concept change',
        description:
          'Later in 2022, the team decided to switch to Qt for the front-end for easier back-end and front-end interoperability, concurrent control of simulations and 3D visualization.'
      },
      {
        item: 'OpenFOAM integration test',
        description:
          'Several tests regarding the use of OpenFOAM integrated into the back-end are performed. The tests indicate that this may be a future prospect for the Penumbra rocket builder toolbox, but requires a large amount of effort.'
      }
    ]
  },
  {
    year: '2023',
    items: [
      {
        item: 'Qt as front-end',
        description:
          'All of the wanted features for the front-end have minimum working examples, ensuring that the concept in Qt will work. This includes rocket builder and trajectory map 3D visualization as well as the possibility for a live updated GUI. Making simulations during launch possible.'
      },
      {
        item: 'Significant overhaul to back-end',
        description:
          'Considerable effort is being put into making a feature rich back-end and efficiently scaling concurrent code.'
      }
    ]
  }
];

const GlassCard = styled(Card)(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: 'rgba(10,10,10, 0.35)',
  backgroundImage: 'none',
  backdropFilter: 'blur(12px)',
  boxShadow: theme.shadows[0],
  gap: 4
}));

const PenumbraTitle = ({ title }) => {
  return (
    <Box className="animate-slide-right">
      <Box
        my={2}
        sx={{
          width: { xs: '25vw', md: '10vw' },
          height: { xs: '1vh', md: '1.2vh' },
          backgroundColor: 'primary.main'
        }}
      />
      <Typography
        mb={3}
        variant="h1"
        fontWeight={700}
        fontSize={{ xs: 36, md: 58 }}
        sx={{
          color: 'white',
          textAlign: 'left'
        }}>
        {title}
      </Typography>
    </Box>
  );
};

const Penumbra = () => {
  const navigate = useNavigate();
  const scrolldown = useRef('');
  const [spec, setSpec] = useState('Code Efficiency');
  const [mockup, setMockup] = useState(PSC_Code);
  const [fadeIn, setFadeIn] = useState(false);

  const getNameDescription = (name) => {
    const result = Specifications.find((spec) => spec.name === name);
    return result ? result.description : '';
  };

  const executeScroll = () =>
    scrolldown.current.scrollIntoView({
      behavior: 'smooth'
    });

  useEffect(() => {
    setFadeIn(true);
  }, [spec]);

  useEffect(() => {
    const gridItems = document.querySelectorAll(
      '.animate-slide-in, .animate-slide-right, .animate-slide-left'
    );

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        } else {
          entry.target.classList.remove('animate');
        }
      });
    });

    gridItems.forEach((item) => {
      observer.observe(item);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleSpecChange = (newSpec) => {
    setFadeIn(false);
    setTimeout(() => {
      setMockup(Specifications.find((spec) => spec.name === newSpec).image);
      setSpec(newSpec);
      setFadeIn(true);
    }, 250);
  };

  return (
    <Fade in style={{ transitionDelay: '500ms' }}>
      <Box
        sx={{
          scrollSnapType: { xs: 'none', md: 'y mandatory' },
          scrollSnapStop: 'always',
          height: '100vh',
          width: '100vw',
          overflow: 'auto',
          overscrollBehavior: 'contain'
        }}>
        <Box
          sx={{
            scrollSnapAlign: 'end',
            overflow: 'hidden'
          }}>
          <Box
            component="img"
            src={penumbra_bg}
            sx={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              objectFit: { xs: 'cover', md: 'fill' },
              transition: 'transform .5s ease-out',
              zIndex: '-1',
              objectPosition: { xs: '32%', md: '100%' }
            }}
          />
          <Header
            sx={{
              width: '100%',
              zIndex: 1000
            }}
          />
          <Box
            sx={{
              width: '100%',
              height: '85vh'
            }}>
            <Grid container xs={12} sx={{ px: 4, pb: 8, width: '100%', height: '100%' }}>
              <Button
                onClick={() => {
                  navigate('/Projects');
                }}
                disableRipple
                variant="outlined"
                color="inherit"
                startIcon={<ArrowBackIosNewIcon />}
                sx={{ mb: 4, borderRadius: 12, color: 'white' }}>
                Back to Projects
              </Button>
              <Grid
                item
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                  position: 'relative'
                }}>
                <Typography
                  variant="h1"
                  fontWeight={600}
                  fontSize={{ xs: 64, md: 96 }}
                  sx={{
                    color: 'white',
                    textAlign: 'Left',
                    lineHeight: '120%',
                    letterSpacing: '0px',
                    fontFamily: 'D-DIN'
                  }}>
                  PENUMBRA
                </Typography>
                <Box
                  mb={15}
                  component="img"
                  src={penumbraLogo}
                  sx={{
                    height: { xs: '20vh', md: '20vh' },
                    width: 'auto',
                    backgroundSize: { xs: 'cover', md: 'contain' }
                  }}
                />
                <Box
                  onClick={executeScroll}
                  className="animate-scrolldown"
                  mb={2}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    height: '22%',
                    width: '5vw',
                    position: 'absolute',
                    bottom: '0'
                  }}>
                  <Typography
                    variant="p"
                    fontWeight={400}
                    fontSize={{ xs: 24, md: 20 }}
                    sx={{
                      color: 'white',
                      textAlign: 'Left',
                      lineHeight: '120%',
                      letterSpacing: '2px',
                      fontFamily: 'D-DIN'
                    }}>
                    SCROLL
                  </Typography>
                  <Box mt={2} sx={{ height: '100%', width: '.10vw', backgroundColor: 'white' }} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          ref={scrolldown}
          sx={{
            height: { xs: 'auto', md: '100vh' },
            minHeight: { xs: '100vh' },
            width: '100%',
            overflow: 'hidden',
            scrollSnapAlign: 'center',
            scrollSnapType: 'y mandatory',
            flexDirection: { xs: 'column', md: 'row' }
          }}>
          <Grid
            container
            p={{ xs: 5, md: 10 }}
            pl={15}
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex'
            }}>
            <Grid
              mt={{ md: 0 }}
              my={{ xs: 20 }}
              item
              xs={12}
              md={5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start'
              }}>
              <Box className={'animate-slide-right'}>
                <Box
                  my={2}
                  sx={{
                    width: { xs: '25vw', md: '10vw' },
                    height: { xs: '1vh', md: '1.2vh' },
                    backgroundColor: 'primary.main'
                  }}
                />
                <Typography
                  mb={5}
                  variant="h1"
                  fontWeight={700}
                  fontSize={{ xs: 42, md: 54 }}
                  sx={{
                    color: 'white',
                    textAlign: 'Left',
                    lineHeight: '120%',
                    letterSpacing: '0px'
                  }}>
                  The <br />
                  open-source <br />
                  rocketeer toolbox
                </Typography>
                <Typography
                  mb={8}
                  variant="h2"
                  fontWeight={400}
                  fontSize={{ xs: 18, md: 24 }}
                  sx={{
                    color: 'white',
                    textAlign: 'left'
                  }}>
                  Click the button below to explore our simulator!
                </Typography>
                <Button
                  variant="outlined"
                  startIcon={<GitHubIcon />}
                  href="https://gitlab.com/propulse-ntnu/penumbra/simulator/"
                  sx={{
                    width: { xs: '100%', md: '55%' },
                    height: { xs: '14%', md: '12%' },
                    borderRadius: '1000px',
                    border: '2px solid white',
                    color: 'white',
                    fontSize: 16,
                    '&:hover': {
                      color: 'white',
                      bgcolor: 'primary.main',
                      border: '2px var(--propulse-blue) solid'
                    }
                  }}>
                  See Documentation
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                height: { xs: '80vh', md: '100vh' },
                position: 'relative',
                display: { xs: 'flex' },
                flexDirection: { xs: 'column' },
                justifyContent: { xs: 'center' }
              }}>
              <Box
                className="animate-slide-left"
                sx={{
                  background: 'linear-gradient(180deg, #000000 0%, #003547 100%)',
                  width: { xs: '68%', md: '40%' },
                  height: { xs: '35vh', md: '50%' },
                  borderRadius: { xs: '15px', md: '20px' },
                  position: 'absolute',
                  top: { xs: '15%', md: '0%' },
                  left: { xs: '20%', md: '40%' },
                  zIndex: 11
                }}>
                <CardMedia
                  component="img"
                  image={penumbra_card1}
                  alt="green iguana"
                  sx={{ objectFit: 'contain', height: '100%' }}
                />
              </Box>
              <Box
                component="img"
                className="animate-slide-right"
                src={penumbra_card2}
                sx={{
                  background: 'linear-gradient(180deg, #000000 0%, #003547 100%)',
                  objectFit: 'cover',
                  width: { xs: '68%', md: '40%' },
                  height: { xs: '35vh', md: '50%' },
                  borderRadius: { xs: '15px', md: '20px' },
                  position: 'absolute',
                  top: { xs: '28%', md: '18%' },
                  left: { xs: '-10%', md: '12%' },
                  zIndex: 10
                }}
              />
              {/* <Box
                sx={{
                  width: { xs: '78vw', md: '60vh' },
                  height: { xs: '50vh', md: '70vh' },
                  backgroundColor: 'primary.main',
                  position: { xs: 'static', md: 'absolute' },
                  borderRadius: { xs: '15px', md: '20px' },
                  top: { xs: '0', md: '8%' },
                  left: { xs: '0', md: '20%' },
                  margin: { xs: 'auto' }
                }}
              /> */}
              <GlassCard
                sx={{
                  width: { xs: '78vw', md: '60vh' },
                  height: { xs: '50vh', md: '70vh' },
                  backgroundColor: 'rgba(0,53,247, 0.4)',
                  backdropFilter: 'blur(5px)',
                  position: { xs: 'static', md: 'absolute' },
                  borderRadius: { xs: '15px', md: '20px' },
                  top: { xs: '0', md: '8%' },
                  left: { xs: '0', md: '20%' },
                  margin: { xs: 'auto' }
                }}
              />
              <Box
                className="animate-slide-left"
                component="img"
                src={deco}
                sx={{
                  width: { xs: '20vh', md: '30vh' },
                  height: { xs: '18vh', md: '25vh' },
                  position: 'absolute',
                  top: { xs: '65%', md: '58%' },
                  left: { xs: '70%', md: '60%' },
                  zIndex: '11'
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: { xs: 'auto', md: '100vh' },
            minHeight: { xs: '100vh' },
            scrollSnapAlign: 'center',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: { xs: 'center' }
          }}>
          <Grid container p={{ xs: 4, md: 10 }} my={{ xs: 5, md: 0 }}>
            <Grid
              item
              className="animate-slide-right"
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start'
              }}>
              <Box
                my={2}
                sx={{
                  width: { xs: '25vw', md: '10vw' },
                  height: { xs: '1vh', md: '1.2vh' },
                  backgroundColor: 'primary.main'
                }}
              />
              <Typography
                mb={5}
                variant="h1"
                fontWeight={700}
                fontSize={{ xs: 32, md: 58 }}
                sx={{
                  color: 'white',
                  textAlign: 'Left',
                  lineHeight: '120%',
                  letterSpacing: '0px'
                }}>
                Technical details.
              </Typography>
              <Typography
                mb={3}
                variant="p"
                fontWeight={300}
                fontSize={{ xs: 18, md: 18 }}
                sx={{
                  color: 'white',
                  textAlign: 'left'
                }}>
                The intention with the Penumbra project is to supplement other excellent trajectory
                software, such as OpenRocket, RocketPy and RASAero with additional improvements to
                user interaction and serve as a dashboard for amateur rocket design.
                <br />
                <br />
                This is achieved by using a backend in C++ for the heavy numerical simulations, and
                a frontend in Qt which has a large ecosystem for graphical user interfaces. The Qt -
                C++ project is built with CMake, and it is our goal to containerize this build
                process and make it easy to add features through open-source development.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          p={{ xs: 4, md: 10 }}
          my={{ xs: 5 }}
          sx={{
            height: { xs: 'auto', md: '100vh' },
            minHeight: { xs: '100vh' },
            width: '100%',
            // bgcolor: 'background.default',
            objectFit: { xs: 'contain', md: 'contain' },
            scrollSnapAlign: 'center',
            overflow: { xs: 'auto', md: 'hidden' },
            overflowX: { xs: 'hidden' },
            display: 'flex',
            flexDirection: 'column'
          }}>
          <PenumbraTitle title="Area of Focus" />
          <Grid
            container
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}>
            <Grid md={6} mt={{ xs: 5 }} item sx={{ position: 'relative' }}>
              <Box
                className={'animate-slide-in'}
                component="img"
                src={mockup}
                sx={{
                  width: { xs: '35vh', md: '70vh' }
                }}
              />
            </Grid>
            <Grid mt={{ xs: 5 }} item md={6}>
              <GlassCard
                className="animate-slide-left"
                sx={{
                  minHeight: { xs: '50vh', md: '42vh' },
                  backdropFilter: { xs: 'none', md: 'blur(6px)' },
                  backgroundColor: { xs: 'transparent', md: 'rgba(10,10,10, 0.35)' },
                  padding: { xs: 0, md: '4% 8%' },
                  borderRadius: { xs: 0, md: '20px' }
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: { xs: 'center', md: 'flex-start' }
                  }}>
                  {Specifications.map((item, index) => (
                    <IconButton
                      key={index}
                      onClick={() => handleSpecChange(item.name)}
                      sx={[
                        {
                          '&:hover': {
                            bgcolor: spec === item.name ? 'primary.main' : ''
                          }
                        },
                        {
                          marginRight: '3%',
                          bgcolor: spec === item.name ? 'primary.main' : '',
                          color: spec === item.name ? 'white' : 'white'
                        }
                      ]}>
                      <Icon component={item.icon} />
                    </IconButton>
                  ))}
                </Box>
                <Fade in={fadeIn} timeout={600} onExited={() => setFadeIn(false)}>
                  <Box sx={{ height: 'auto' }}>
                    <Typography
                      my={2}
                      variant="h1"
                      fontWeight={600}
                      fontSize={{ xs: 32, md: 42 }}
                      sx={{
                        color: 'white',
                        textAlign: { xs: 'center', md: 'left' }
                      }}>
                      {spec}
                    </Typography>
                    <Typography
                      variant="h1"
                      fontWeight={{ xs: 300, md: 200 }}
                      fontSize={{ xs: 18, md: 20 }}
                      sx={{
                        color: 'white',
                        textAlign: 'left'
                      }}>
                      {getNameDescription(spec)}
                    </Typography>
                  </Box>
                </Fade>
              </GlassCard>
            </Grid>
          </Grid>
        </Box>
        <Box
          px={{ xs: 4, md: 10 }}
          pt={{ xs: 10, md: 10 }}
          sx={{
            width: '100%',
            scrollSnapAlign: 'start'
          }}>
          <PenumbraTitle title="Penumbras Timeline." />
          {TimelineItems.map((item, index) => (
            <Box
              key={index}
              py={{ xs: 4, md: 15 }}
              sx={{
                zIndex: '5'
              }}>
              <Grid container sx={{ position: 'relative' }}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}>
                  <GlassCard
                    className="animate-slide-right"
                    sx={{
                      position: 'sticky',
                      top: { xs: '100vh', md: '40vh' },
                      padding: '3%, 5%',
                      marginY: { xs: '5%', md: '13%' }
                    }}>
                    <Typography
                      px={2}
                      py={2}
                      variant="h1"
                      fontWeight={700}
                      fontSize={{ xs: 42, md: 72 }}
                      sx={{
                        color: 'white',
                        textAlign: 'center',
                        transition: 'opacity 1s ease-out'
                      }}>
                      Year <span style={{ color: 'var(--propulse-blue)' }}>{item.year}</span>
                    </Typography>
                  </GlassCard>
                </Grid>
                <Grid
                  item
                  pl={{ xs: 0, md: 5 }}
                  xs={12}
                  md={8}
                  my={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                  {item.items.map((subItem, index) => (
                    <GlassCard
                      key={index}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: { xs: '100%', md: '85%' },
                        padding: '3% 5%',
                        margin: { xs: '8%', md: '6%' }
                      }}>
                      <Box
                        my={2}
                        sx={{
                          width: { xs: '20vw', md: '6vw' },
                          height: { xs: '1.5vw', md: '.4vw' },
                          bgcolor: 'primary.main'
                        }}
                      />
                      <Typography
                        mb={3}
                        variant="h1"
                        fontWeight={600}
                        fontSize={{ xs: 28, md: 32 }}
                        sx={{
                          color: 'white',
                          transition: 'opacity 1s ease-out'
                        }}>
                        {subItem.item}
                      </Typography>
                      <Typography
                        variant="p"
                        fontWeight={300}
                        fontSize={{ xs: 18, md: 18 }}
                        sx={{
                          color: 'white',
                          transition: 'opacity 1s ease-out'
                        }}>
                        {subItem.description}
                      </Typography>
                    </GlassCard>
                  ))}
                </Grid>
              </Grid>
            </Box>
          ))}
          <Footer style={{ margin: 'auto' }} color="white" />
        </Box>
        <div style={{ height: '1px', scrollSnapAlign: 'start' }}></div>
      </Box>
    </Fade>
  );
};

export default Penumbra;
